import * as React from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { debounce } from '@mui/material/utils';
import { getLocations } from '../../service/commonService';

interface PlaceType {
  label: string;
  value: string;
}
interface Prop {
  onSelect: (str?: string) => void;
  initialValue?: string;
}

export default function LocationAutoComplete({ onSelect, initialValue }: Prop) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        getLocations(request.input).then((resp: any) => {
          let data = resp.data.results
            .filter((item: any) => !!item.city)
            .map((item: any) => {
              return {
                label: `${item.city}, ${item.country}`,
                value: `${item.city}, ${item.country}`,
              };
            });
          data = data.filter(
            (value: any, index: number, self: any) =>
              index === self.findIndex((t: PlaceType) => t.label === value.label),
          );
          callback(data);
        });
      }, 400),
    [],
  );

  React.useEffect(() => {
    if (inputValue) {
      fetch({ input: inputValue }, (results?: PlaceType[]) => {
        if (results) setOptions([...results]);
      });
    }
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id='location-autocomplete'
      size='small'
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={initialValue ? { label: initialValue, value: initialValue } : null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText='No locations'
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onSelect(newValue?.value);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder='Start typing to search' label='' fullWidth />
      )}
    />
  );
}
