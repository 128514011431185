import axiosClient from '../common/axiosClient';
import { UserDetails } from '../models/user.model';

export async function getUser() {
  return axiosClient.get('/user');
}

export async function getAllUsers(role?: string) {
  return axiosClient.get('/user/all', { params: { role } });
}

export async function addUser(user: UserDetails) {
  return axiosClient.post('/user', user);
}

export async function updateUser(user: UserDetails) {
  return axiosClient.patch('/user', user);
}
