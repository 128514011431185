import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { getRole } from '../../common/commonUtil';
import globalObject from '../../common/global-variables';
import ChangePassword from './change-password';
import { PEOPLETECH_REGEX } from '../../constants/common';

const Profile = () => {
  const [showChangePassowrd, setShowchangePassword] = useState(false);
  const handleClick = (_event: any) => {
    setShowchangePassword(!showChangePassowrd);
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className='user-profile-card' style={{ width: '50%' }}>
          <PersonIcon sx={{ width: '5em', height: '5em' }}></PersonIcon>
          <h1>{`${globalObject.userObject.firstName} ${globalObject.userObject.lastName}`}</h1>
          <p className='user-profile-title'>{getRole(globalObject.userObject)}</p>
          <p style={{ fontWeight: '500' }}>{globalObject.userObject.email}</p>
          <p
            style={{
              fontWeight: '500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <SmartphoneIcon sx={{ fontSize: '1rem' }}></SmartphoneIcon>{' '}
            {' ' + globalObject.userObject.phoneNumber}
          </p>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', borderRight: '2px dotted #ccc' }}>
              Emp ID: {globalObject.userObject.employeeCode}
            </div>
            <div style={{ width: '50%' }}>{globalObject.userObject.operations.name}</div>
          </div>

          <p>
            {(globalObject.properties.enable_office_integration == 'false' ||
              !PEOPLETECH_REGEX.test(globalObject.userObject.email)) && (
              <button onClick={(event) => handleClick(event)}>Change Password</button>
            )}
          </p>
        </div>
        <div style={{ width: '50%' }}>
          {showChangePassowrd && <ChangePassword></ChangePassword>}
        </div>
      </div>
    </>
  );
};
export default Profile;
