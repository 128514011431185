import {
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { InterviewProcessDetails } from '../../../models/sjd-form.model';
import { useState } from 'react';
import SectionHeaderWithEdit from '../../common/section-heading';
import { getLabel, isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';

interface InterviewProcessProps {
  previous?: () => void;
  save: (values: InterviewProcessDetails) => Promise<boolean>;
  mode?: 'create-page' | 'details-page';
  values: InterviewProcessDetails | undefined;
}

function getError(formik: FormikProps<any>, fieldName: string, submitted: boolean) {
  if ((formik.touched[fieldName] || submitted) && formik.errors[fieldName])
    return formik.errors[fieldName]?.toString();
  return null;
}

const InterviewProcess: React.FC<InterviewProcessProps> = ({
  previous = () => {},
  save,
  mode = 'create-page',
  values,
}: InterviewProcessProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'create-page');
  const interviewProcessSchema = Yup.object().shape({
    isOnlineTest: Yup.boolean().required('Coding test is required'),
    noOfTechnicalInterview: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .positive('The value must be positive')
      .required('No of technical interview is required'),
    isClientRound: Yup.boolean().required('Client round is required'),
  });
  const formik = useFormik({
    initialValues: values || {
      isOnlineTest: false,
      noOfTechnicalInterview: undefined,
      isClientRound: false,
    },
    onSubmit: (values) => {
      save(values).then(() => setEdit(mode === 'create-page'));
    },
    validationSchema: interviewProcessSchema,
    validateOnBlur: true,
  });

  const submit = () => {
    setSubmitted(true);
    formik.handleSubmit();
  };

  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Interview Process'
            edit={edit}
            hideIcon={!isPermissionsWrite(MODULE.SJD,SubModuleTypes.JD_INTERVIEW_PROCESS)}
            click={() => {
              if (edit) {
                setSubmitted(true);
                formik.handleSubmit();
              } else {
                setEdit(!edit);
              }
            }}
          />
        )}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={5} width='100%' pt={2} margin={0} paddingLeft={3} pb={2}>
            <Grid className='pt-10' item alignItems='center' pt={0} md={3} lg={3} xs={3}>
              <InputLabel className='field-label' id='demo-controlled-radio-buttons-group'>
                Online Test *
              </InputLabel>
              {edit ? (
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='isOnlineTest'
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                  value={formik.values.isOnlineTest}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}>
                  <FormControlLabel value={true} control={<Radio />} label='Yes' />
                  <FormControlLabel value={false} control={<Radio />} label='No' />
                </RadioGroup>
              ) : (
                <span>{formik.values.isOnlineTest ? 'Yes' : 'No'}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'isOnlineTest', submitted)}</div>
            </Grid>
            <Grid className='pt-10' item alignItems='center' pt={0} md={3} lg={3} xs={3}>
              <InputLabel className='field-label' id='noOfTechnicalInterview-input-field'>
                No of Technical Interview *
              </InputLabel>
              {edit ? (
                <TextField
                  value={
                    formik.values.noOfTechnicalInterview ? formik.values.noOfTechnicalInterview : ''
                  }
                  fullWidth
                  id='noOfTechnicalInterview-input-field'
                  size='small'
                  name='noOfTechnicalInterview'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({
                      target: { name: 'noOfTechnicalInterview', value: numericValue },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  tabIndex={0}
                />
              ) : (
                <span>{getLabel(formik.values.noOfTechnicalInterview)}</span>
              )}
              <div className='mt-0 form-err-msg'>
                {getError(formik, 'noOfTechnicalInterview', submitted)}
              </div>
            </Grid>
            <Grid className='pt-10' item alignItems='center' pt={0} md={3} lg={3} xs={3}>
              <InputLabel className='field-label' id='clientRound-select-block'>
                Client Round *
              </InputLabel>
              {edit ? (
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='isClientRound'
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                  value={formik.values.isClientRound}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}>
                  <FormControlLabel value={true} control={<Radio />} label='Yes' />
                  <FormControlLabel value={false} control={<Radio />} label='No' />
                </RadioGroup>
              ) : (
                <span>{formik.values.isClientRound ? 'Yes' : 'No'}</span>
              )}
              <div className='mt-0 form-err-msg'>
                {getError(formik, 'isClientRound', submitted)}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginRight: 3 }} onClick={() => previous()} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button sx={{ marginLeft: 3 }} onClick={() => submit()} className='default-btn bg-btn'>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};
export default InterviewProcess;
