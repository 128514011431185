import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Load English locale
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IconButton, Typography, Divider, Box, Paper } from '@mui/material';

dayjs.extend(localizedFormat);

const UpCommingInterviews = () => {
  const formatDate = (date: dayjs.Dayjs | null) => {
    if (!date) return '';
    return date.format('dddd MMMM DD, YYYY');
  };

  const interviews = [
    {
      startTime: '11:00 AM',
      endTime: '12:00 PM',
      type: 'In Person',
      name: 'Ramana Joshi',
      position: 'Senior Developer',
    },
    {
      startTime: '01:00 PM',
      endTime: '01:30 PM',
      type: 'Team Meeting',
      name: 'Ramana Joshi',
      position: 'Senior Developer',
    },
    {
      startTime: '02:00 PM',
      endTime: '02:30 PM',
      type: 'Teams Meeting',
      name: 'Rabindrath Chaterjee',
      position: 'DevOps',
    },
    {
      startTime: '03:00 PM',
      endTime: '03:30 PM',
      type: 'Teams Meeting',
      name: 'Sushanth Kaushik',
      position: 'Senior UX Designer',
    },
    {
      startTime: '04:00 PM',
      endTime: '04:30 PM',
      type: 'Teams Meeting',
      name: 'Ravi Mallam',
      position: 'Java Developer',
    },
  ];

  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '3.62rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'flex', width: 'max-content' }}>
            <img
              src={require('../../assets/dashboard-assets/event.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
            <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
              Upcoming Interviews
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
            <img
              src={require('../../assets/dashboard-assets/event.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
            <span
              className='formatted-date'
              style={{ height: '24px', fontSize: '18px', fontWeight: 600 }}>
              {formatDate(dayjs())}
            </span>
            <IconButton>
              <KeyboardArrowDownIcon />
            </IconButton>
            <img
              src={require('../../assets/dashboard-assets/more_vert.png')}
              style={{ marginRight: '10px' }}
            />
          </div>
        </div>
        <hr style={{ color: '#C1C0C0', marginBlockEnd: '0em', marginBlockStart: '0em' }}></hr>
        <Box
          sx={{
            // height: '22.4rem',
            flexGrow: 1,
            overflowY: 'auto',
            marginBottom: '1.25rem',
            marginTop: '1.75rem',
            marginLeft: '1.25rem',
            gap: '1.25rem',
            height: '18.4rem',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '1.04rem',
          }}>
          {interviews.map((interview, index) => (
            <div key={index}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ minWidth: '80px', textAlign: 'right', paddingRight: '8px' }}>
                  <Typography sx={{ fontWeight: 400, color: '#333333' }}>
                    {interview.startTime}
                  </Typography>
                  <Typography sx={{ fontWeight: 400, color: '#9e9e9e' }}>
                    {interview.endTime}
                  </Typography>
                </Box>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    marginRight: '8px',
                    borderColor: '#C1C0C0',
                    width: '0.125rem',
                    height: '2.9375rem',
                  }}
                />
                <Box>
                  <Typography variant='body2' color='textSecondary'>
                    {interview.type}
                  </Typography>
                  <Typography variant='body1' sx={{ color: '#333' }}>
                    Interview with <strong>{interview.name}</strong> for the{' '}
                    <strong>{interview.position}</strong> job
                  </Typography>
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Paper>
    </>
  );
};
export default UpCommingInterviews;
