import React, { useEffect, useState } from 'react';
import './login.scss';
import ptglogo from './../assets/login/ptglogo.png';
import person from './../assets/login/person.png';
import location from './../assets/login/location.png';
import lock from './../assets/login/lock.png';
import Ellipse from './../assets/login/Ellipse-25.png';
import tadLogo from './../assets/login/tad-logo.png';
import microsoft from './../assets/login/Microsoft.png';
import MicrosoftLogin from 'react-microsoft-login';
import {
  Backdrop,
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  authenticate,
  login as loginApi,
  // refreshToken,
} from '../../service/securityService';
import useNavigate from '../../common/useNavigate';
import { ReactComponent as Visibility } from './../assets/login/visibility.svg';
import { ReactComponent as VisibilityOff } from './../assets/login/visibility_off.svg';
import globalObject from '../../common/global-variables';
import { getProperties } from '../../service/commonService';

function LoginRevamp() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshtoken');
  const [navigate, redirect] = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const target = params.get('from');
  const [showPassword, setShowPassword] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [microsoftLoginVisible, setMicrosoftLoginVisible] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      login(values);
    },
  });

  useEffect(() => {
    const promise = !globalObject.properties
      ? getProperties()
      : new Promise((resolve) => {
          resolve({ data: globalObject.properties });
        });
    promise
      .then((resp: any) => {
        globalObject.properties = resp.data;
        setMicrosoftLoginVisible(true);
        console.log(globalObject.properties?.microsoft_client_id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const authHandler = (err: any, data: any) => {
    let refreshToken: any;
    for (const key in localStorage) {
      if (key.indexOf('refreshtoken') > -1) {
        const tokenObj: any = localStorage.getItem(key);
        try {
          refreshToken = JSON.parse(tokenObj).secret;
        } catch {
          refreshToken = '';
        }
      }
    }
    if (!err) {
      setShowOverlay(true);
      authenticate({ idToken: data.idToken, accessToken: data.accessToken })
        .then((resp) => {
          const config = localStorage.getItem('breadcrumb-config');
          const flag = localStorage.getItem('switch');
          localStorage.clear();
          if (config) localStorage.setItem('breadcrumb-config', config);
          localStorage.setItem('switch', flag ? flag : 'false');
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('refreshtoken', refreshToken);
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'Dashboard',
                  link: '/',
                },
              ],
              true,
            );
          }
        })
        .catch((_err) => {
          setShowOverlay(false);
        });
    }
  };

  const login = (values: any) => {
    setShowOverlay(true);
    loginApi(values)
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        if (resp.data.roles.filter((roles: any) => roles.name == 'intern').length > 0) {
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'LMS Dashboard',
                  link: '/lms',
                },
              ],
              true,
            );
          }
        } else {
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'NewDashboard',
                  link: '/',
                },
              ],
              true,
            );
          }
        }
      })
      .catch((_err) => {
        setShowOverlay(false);
      });
  };

  return (
    <div className='login-background'>
      <div className='login-container'>
        <div className='div-container'>
          <div className='left-login-div'>
            <div className='ptg-logo'>
              <img src={ptglogo} alt='peopletech-logo'></img>
            </div>
            <div className='text-container'>
              <div className='text-header'>Talent Acquisition & Development</div>
              <div className='text-header-1'>How it works?</div>
              <div className='text-content'>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <img className='icons-bg-img' src={Ellipse} alt='ellipse' />
                    <img className='login-icons' src={person}></img>
                  </div>
                  <Typography>Log In to Manage Your Talent Pipeline</Typography>
                </div>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <img className='icons-bg-img' src={Ellipse} alt='ellipse' />
                    <img className='login-icons' src={location}></img>
                  </div>
                  <Typography>Seamlessly Track Your Applicants</Typography>
                </div>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <img className='icons-bg-img' src={Ellipse} alt='ellipse' />
                    <img className='login-icons' src={lock}></img>
                  </div>
                  <Typography>Unlock Your Recruiting Power</Typography>
                </div>
              </div>
            </div>
          </div>
          <div className='right-login-div'>
            <div className='sign-in-container'>
              <img className='sign-in-logo' src={tadLogo} alt='TAD Logo' />
              <Typography className='sign-in-header' variant='h5' component='h1'>
                Sign In
              </Typography>
              <div className='sign-in-content'>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    className='sign-in-fields'
                    placeholder='Email*'
                    variant='outlined'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='username'
                  />
                  <OutlinedInput
                    className='sign-in-fields'
                    placeholder='Password*'
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='password'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'>
                          {showPassword ? (
                            <VisibilityOff className='my-svg-icon' />
                          ) : (
                            <Visibility className='my-svg-icon' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Button fullWidth type='submit' className='sign-in-button'>
                    Sign In
                  </Button>
                </form>
                <div>
                  <Link
                    href='#'
                    variant='body2'
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                    Forgot Password?
                  </Link>
                </div>
                <div className='divider-container'>
                  <div className='divider-line'></div>
                  <Typography className='divider-text'>or continue with</Typography>
                  <div className='divider-line'></div>
                </div>
                <Button className='microsoft-login-btn'>
                  {microsoftLoginVisible && (
                    <MicrosoftLogin
                      withUserData
                      useLocalStorageCache
                      graphScopes={[
                        'Calendars.ReadWrite',
                        'Mail.ReadWrite',
                        'Mail.Send',
                        'OnlineMeetings.ReadWrite',
                        'openid',
                        'profile',
                        'User.Read',
                        'email',
                      ]}
                      clientId={
                        globalObject.properties ? globalObject.properties.microsoft_client_id : ''
                      }
                      authCallback={authHandler}
                      redirectUri='/_blank.html'>
                      <div style={{ display: 'flex' }}>
                        <span className='microsoft-login-btn-text'>Login with</span>
                        <img className='microsoft-login-btn-img' src={microsoft} />
                      </div>
                      <div className='right-block'></div>
                    </MicrosoftLogin>
                  )}
                </Button>

                <div className='terms-conditions'>
                  By clicking continue, you agree to our{' '}
                  <Link sx={{ cursor: 'pointer' }} color={'#fff'}>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link sx={{ cursor: 'pointer' }} color={'#fff'}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='login-footer'>© 2024 People Tech Group all rights reserved</div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}
        onClick={() => setShowOverlay(false)}>
        <div className='loader-container'>
          <div className='loader-line'>
            <img src={tadLogo} alt='Loading' className='loader-image' />
          </div>
        </div>
      </Backdrop>
    </div>
  );
}

export default LoginRevamp;
