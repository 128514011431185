import {
  Autocomplete,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  AlertColor,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UserDetails } from '../../models/user.model';
import './user-setting.scss';
import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';
import { addUser, getAllUsers, updateUser } from '../../service/userService';
import Notify from '../common/notify';
import { AxiosError } from 'axios';
import { ROLES } from '../../constants/common';
import { getIdFromName } from '../../common/commonUtil';

interface Props {
  user: UserDetails | undefined;
  isEdit: boolean;
  handleClose: (event: any, reason: string) => void;
}

const AddUser = ({ user, isEdit, handleClose }: Props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  };
  const [filteredOptions, setFilteredOptions] = useState(
    globalObject.lookupvalues[LookupTypes.ROLE],
  );
  const [roles, setRoles] = useState([] as any[]);

  const userDetailsSchema = roles.map((e) => e.name).includes('intern')
    ? Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Please enter a valid email').required('Email is required'),
        operations: Yup.number().required('Operations is required'),
        roles: Yup.array().required('Role is required'),
        level: Yup.string().required('level is required'),
        managerId: Yup.string().required('manager is required'),
      })
    : Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Please enter a valid email').required('Email is required'),
        operations: Yup.number().required('Operations is required'),
        roles: Yup.array().required('Role is required'),
      });
  const [showApiResponseNotification, setShowApiResponseNotification] = useState(false);
  const [managers, setManagers] = useState([] as any[]);
  const [apiResponseNotification, setApiResponseNotification] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: any;
  });
  const formik = useFormik({
    initialValues: user || {
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      operations: '',
      roles: [],
      level: '',
      managerId: '',
    },
    onSubmit: () => {
      const apiCall: Promise<any> = isEdit
        ? updateUser(formik.values as UserDetails)
        : addUser(formik.values as UserDetails);
      apiCall
        .then(() => {
          handleClose({}, 'submitted');
        })
        .catch((error: AxiosError) => {
          if (error.response !== undefined && error.response.data !== undefined) {
            const data: any = error.response.data;
            setApiResponseNotification({
              severity: 'error',
              msg:
                data.message !== undefined
                  ? data.message
                  : 'Something went wrong, please try again!',
            });
            setShowApiResponseNotification(true);
          }
        });
    },
    validationSchema: userDetailsSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    getAllUsers(getIdFromName(ROLES.MANAGER, LookupTypes.ROLE)).then((res) =>
      setManagers(res.data),
    );
  }, []);

  const handleRoleChange = (event: any, newValue: any) => {
    formik.setFieldValue('roles', newValue);
    setRoles(newValue);
    if (newValue.some((role: any) => role.name === ROLES.INTERN)) {
      formik.setFieldValue('managerId', '');
      setFilteredOptions(
        globalObject.lookupvalues[LookupTypes.ROLE].filter(
          (role: any) => role.name !== ROLES.MANAGER,
        ),
      );
    } else if (newValue.some((role: any) => role.name === ROLES.MANAGER)) {
      formik.setFieldValue('internId', '');
      setFilteredOptions(
        globalObject.lookupvalues[LookupTypes.ROLE].filter(
          (role: any) => role.name !== ROLES.INTERN,
        ),
      );
    } else {
      setFilteredOptions(globalObject.lookupvalues[LookupTypes.ROLE]);
    }
  };

  return (
    <>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <h2>Add user</h2>
          <form
            style={{ marginBottom: '10px' }}
            onSubmit={(values) => {
              formik.handleSubmit(values);
            }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormControl
                          error={!!formik.errors.firstName}
                          variant='standard'
                          className='user-form'>
                          <InputLabel>First Name *</InputLabel>
                          <Input
                            id='fname'
                            name='firstName'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}></Input>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl
                          error={!!formik.errors.lastName}
                          variant='standard'
                          className='user-form'>
                          <InputLabel>Last Name *</InputLabel>
                          <Input
                            id='lname'
                            name='lastName'
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}></Input>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl
                          error={!!formik.errors.email}
                          variant='standard'
                          className='user-form'>
                          <InputLabel>Email *</InputLabel>
                          <Input
                            id='email'
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}></Input>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl
                          error={!!formik.errors.operations}
                          variant='standard'
                          className='user-form'>
                          <InputLabel>Operations *</InputLabel>
                          <Select
                            className='operation-select-field'
                            fullWidth
                            labelId='operation'
                            value={formik.values.operations}
                            name='operations'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id='user-operations-select'>
                            <MenuItem disabled selected>
                              Select
                            </MenuItem>
                            {globalObject.lookupvalues[LookupTypes.OPERATION].map(
                              (operation: any, index: number) => (
                                <MenuItem key={index} value={operation.id}>
                                  {operation.name}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl
                          variant='standard'
                          className='user-form'
                          sx={{ width: '100%' }}>
                          <Autocomplete
                            multiple
                            id='user-roles'
                            options={filteredOptions}
                            sx={
                              formik.errors.roles
                                ? { width: 180, borderBottom: '1px solid red' }
                                : { width: 180, borderBottom: '1px solid #888' }
                            }
                            limitTags={2}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            filterSelectedOptions
                            onBlur={() => formik.setFieldTouched('roles', true, true)}
                            defaultValue={user?.roles ? user.roles : []}
                            renderInput={(params) => (
                              <TextField {...params} className='' label='Roles *' />
                            )}
                            onChange={handleRoleChange}
                          />
                        </FormControl>
                      </td>
                      {formik.values.roles &&
                        formik.values.roles.find((role: any) => role.name === ROLES.INTERN) && (
                          <td>
                            <FormControl
                              error={!!formik.errors.level}
                              variant='standard'
                              className='user-form'>
                              <InputLabel>Level *</InputLabel>
                              <Select
                                className='operation-select-field'
                                fullWidth
                                labelId='level'
                                value={formik.values.level}
                                name='level'
                                onChange={(e: any) => {
                                  formik.setFieldValue('level', e.target.value);
                                }}
                                id='user-operations-select'>
                                <MenuItem disabled selected>
                                  Select
                                </MenuItem>
                                {globalObject.lookupvalues[LookupTypes.LEVEL].map((level: any) => (
                                  <MenuItem key={level.id} value={level.id}>
                                    {level.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </td>
                        )}
                    </tr>
                  </tbody>
                </table>
              </div>
              {formik.values.roles &&
                formik.values.roles.find((role: any) => role.name === ROLES.INTERN) && (
                  <div style={{ marginTop: '10px', marginLeft: '12px', marginRight: '-6px' }}>
                    <FormControl
                      variant='standard'
                      error={!!formik.errors.managerId}
                      sx={{ width: '100%' }}>
                      <InputLabel>Manager *</InputLabel>
                      <Select
                        id='manager'
                        fullWidth
                        size='small'
                        value={formik.values.managerId}
                        onChange={(e: any) => {
                          formik.setFieldValue('managerId', e.target.value);
                        }}>
                        <MenuItem key={0} value=' ' disabled>
                          Select Manager
                        </MenuItem>
                        {managers.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={
                              manager.id
                            }>{`${manager.firstName} ${manager.lastName}`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
            </div>
          </form>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className='default-btn bg-btn'
              sx={{ marginTop: 3 }}
              onClick={() => formik.handleSubmit()}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Notify
        severity={apiResponseNotification.severity}
        open={showApiResponseNotification}
        onClose={() => setShowApiResponseNotification(false)}>
        <span>{apiResponseNotification.msg}</span>
      </Notify>
    </>
  );
};

export default AddUser;
