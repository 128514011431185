export const ROLES = {
  ADMIN: 'admin',
  LEAD: 'lead',
  RECRUITER: 'recruiter',
  EVALUATOR: 'evaluator',
  INTERN: 'intern',
  MANAGER: 'manager',
};

export const MODULE = {
  DASHBOARD: 'Dashboard',
  SJD: 'Job Definitions',
  QUESTIONNAIRE: 'Questionnaire',
  CANDIDATE_SEARCH: 'Candidate Search',
  LMS: 'LMS',
  REPORTING: 'Reporting',
  EMPLOYEE_REFERRAL: 'Employee Referral',
  APPLICANTS: 'Applicants',
  SETTINGS: 'Settings',
};

export const SubModuleTypes = {
  INTERVIEW_SCHEDULES: 'Interview Schedules',
  CANDIDATES_TO_AUDIT: 'Candidates To Audit',
  SOURCING_AUDIT_TREND: 'Sourcing and Audit Trend',
  JD_CANDIDATES: 'JD-Candidates',
  JD_SJD_DETAILS: 'JD-SJD Details',
  JD_HIRING_TEAM: 'JD-Hiring Team',
  JD_SOURCING_STRATEGY: 'JD-Sourcing Strategy',
  JD_INTERVIEW_PROCESS: 'JD-Interview Process',
  JD_QUESTIONNAIRE: 'JD-Questionnaire',
  JD_ACTIVITY: 'JD-Activity',
  CANDIDATE_JOB_REQUIREMENT: 'Candidate-Job Requirement',
  CANDIDATE_CANDIDATE_DETAILS: 'Candidate-Candidate Details',
  CANDIDATE_EMPLOYEMENT: 'Candidate-Employment',
  CANDIDATE_EDUCATION: 'Candidate-Education',
  CANDIDATE_ADDITIONAL_INFO: 'Candidate-Additional Info',
  CANDIDATE_ATTACH_DOCUMENTS: 'Candidate-Attach Documents',
  CANDIDATE_RESUME_AND_FEEDBACK: 'Candidate-Rating-Feedback',
  CANDIDATE_INTERVIEW_FEEDBACK: 'Candidate-Interview-Feedback',
  CANDIDATE_INTERVIEW: 'Candidate-Interview',
  CANDIDATE_QUESTIONNAIRE: 'Candidate-Questionnaire',
  CANDIDATE_AUDIT: 'Candidate-Audit',
  CANDIDATE_ACTIVITY: 'Candidate-Activity',
  CANDIDATE_SUB_STATUS: 'Candidate Sub Status',
  CANDIDATE_FUNNEL_CHART: 'Candidate Funnel Chart',
  SOURCE_CHANNEL: 'Source Channel',
  JOB_DEFINATION_OVERVIEW: 'Job Definition Overview',
  CANDIDATE_COUNT_BY_STATUS: 'Candidate Count By Status',
  AVERAGE_RATING: 'Average Rating',
  AWAITING_PROFILER_RATING: 'Awaiting Profiler Rating',
  YOUR_INTERVIEWS: 'Your Interviews',
  DATA_ANALYTICS: 'Data Analytics',
  LMS_DASHBOARD: 'Lms Dashboard',
  LMS_REPORT: 'Lms Report',
  COURSE_CATALOGUE: 'Course Catalogue',
  PROFILE: 'Profile',
  USERS: 'Users',
  MY_TEAMS: 'My Teams',
  GLOBAL_SETTINGS: 'Global Settings',
};

export enum SJD_STATUS {
  NEW = 'New',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'In Progress',
  ON_HOLD = 'On Hold',
  COMPLETED = 'Completed',
}

export enum CANDIDATE_STATUS {
  PROFILE_UPLOADED = 'Profile Uploaded',
  QC_RATED = 'QC Rated',
  PROFILER_RATED = 'Profiler Rated',
  ONLINE_TEST_SCHEDULED = 'Online Test Scheduled',
  ONLINE_TEST_CLEARED = 'Online Test cleared',
  TECH_INTERVIEW_1_SCHEDULED = 'Tech Interview-1 Scheduled',
  TECH_INTERVIEW_1_CLEARED = 'Tech Interview-1 cleared',
  TECH_INTERVIEW_2_SCHEDULED = 'Tech Interview-2 Scheduled',
  TECH_INTERVIEW_2_CLEARED = 'Tech Interview-2 cleared',
  TECH_INTERVIEW_3_SCHEDULED = 'Tech Interview-3 Scheduled',
  TECH_INTERVIEW_3_CLEARED = 'Tech Interview-3 cleared',
  TECH_INTERVIEW_4_SCHEDULED = 'Tech Interview-4 Scheduled',
  TECH_INTERVIEW_4_CLEARED = 'Tech Interview-4 cleared',
  TECH_INTERVIEW_5_SCHEDULED = 'Tech Interview-5 Scheduled',
  TECH_INTERVIEW_5_CLEARED = 'Tech Interview-5 cleared',
  CLIENT_INTERVIEW_SCHEDULED = 'Client Interview Scheduled',
  CLIENT_INTERVIEW_CLEARED = 'Client Interview Cleared',
  HR_INTERVIEW_SCHEDULED = 'HR Interview Scheduled',
  HR_INTERVIEW_CLEARED = 'HR Interview Cleared',
  DOCUMENT_VERIFICATION_STARTED = 'Document Verification Started',
  DOCUMENT_VERIFICATION_COMPLETE = 'Document Verification Completed',
  OFFER_APPROVED = 'Offer approved',
  OFFER_RELEASED = 'Offer Released',
  OFFER_ACCEPTED = 'Offer accepted',
  CANDIDATE_JOINED = 'Joined',
}

export const INTERVIEW_RESULT = Object.freeze({
  SELECTED: 'Selected',
  REJECTED: 'Rejected',
  ON_HOLD: 'On hold',
});

export enum CANDIDATE_SUB_STATUS {
  NOT_INTERESTED = 'Not Interested',
  REJECTED = 'Rejected',
  CALL_BACK = 'Callback',
  ON_HOLD = 'On Hold',
  DROPPED = 'Dropped',
}

export enum CANDIDATE_SOURCE {
  REFERRAL = 'Employee Referral',
}

export const PEOPLETECH_REGEX = /@peopletech.com$/;
