import { Paper, InputLabel, TextField, Select, MenuItem, Button, Tooltip } from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { CandidateDetailsModel } from './../../../models/candidate-form.model';
import { useState } from 'react';
import '../candidate.scss';
import SectionHeaderWithEdit from './../../common/section-heading';
import {
  getLabel,
  getNameFromId,
  isPermissionsWrite,
  isUserRole,
} from '../../../common/commonUtil';
import Autocomplete from '@mui/material/Autocomplete';
import globalObject from './../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import LocationAutoComplete from '../../common/location-autocomplete';
import { CANDIDATE_SOURCE, MODULE, ROLES, SubModuleTypes } from '../../../constants/common';

interface Props {
  source?: string;
  values: CandidateDetailsModel;
  skills: any[];
  candidateCheck?: (details: any) => void;
  mode?: 'create-page' | 'details-page';
  next: (value: CandidateDetailsModel) => Promise<boolean>;
  previous?: () => void;
}

const CandidateDetailsForm = ({
  source,
  values,
  skills,
  candidateCheck,
  mode = 'create-page',
  next,
  previous = () => {},
}: Props) => {
  const ctcFormat = globalObject.userObject.operations.name.startsWith('Indian')
    ? 'en-IN'
    : 'en-US';
  const candidateDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(500, 'Name should not exceed 500 characters'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required')
      .max(500, 'Email should not exceed 500 characters'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .max(200, 'Phone Number should not exceed 200 characters'),
    location: Yup.string().required('Location is required'),
    noticePeriod: Yup.number()
      .integer('The value must not be a decimal')
      .typeError('The value must be a number')
      .moreThan(-1, 'The value must be positive')
      .required('Notice period is required'),
    experience: Yup.number()
      .typeError('The value must be a number')
      .moreThan(-1, 'The value must be positive')
      .required('Experience is required'),
    skills: Yup.array().of(Yup.object()).min(1, 'Skills are required'),
    sourceChannel: Yup.number().required('Source Channel is required'),
    currentRole: Yup.string().max(300, 'Current Role should not exceed 300 characters').nullable(),
    linkedInUrl: Yup.string()
      .max(5000, 'LinkedIn Url should not exceed 5000 characters')
      .nullable(),
    visaType: Yup.string().max(1000, 'Visa Type should not exceed 1000 characters').nullable(),
    visaStatus: Yup.string().max(1000, 'Visa Status should not exceed 1000 characters').nullable(),
    visaValidity: Yup.string()
      .max(1000, 'Visa Validity should not exceed 1000 characters')
      .nullable(),
  });
  const formik = useFormik({
    initialValues: values,
    validationSchema: candidateDetailsSchema,
    onSubmit: (e) => {
      if (candidateCheck) {
        candidateCheck({ email: e.email, phone: e.phoneNumber });
      }
      const val = {
        ...formik.values,
        expectedCtc: Number(formik.values.expectedCtc),
        currentCtc: Number(formik.values.currentCtc),
      };
      next(val).then(() => setEdit(mode === 'create-page'));
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode == 'create-page');

  const getError = (formik: FormikProps<any>, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };
  const setLocationCallback = (loc?: string) => {
    formik.setFieldValue('location', loc);
  };

  const formatCtc = (val: any, fieldName: string) => {
    if (val.length > 50) return;
    const t = val.replaceAll(',', '').replace(/[a-z]/g, '');

    if (t.length == 0 || Number(t) === 0) {
      formik.setFieldValue(fieldName, t);
    } else {
      formik.setFieldValue(fieldName, Number(t));
    }
  };

  return (
    <div className='candidate'>
      <Paper sx={{ padding: '1px 16px 1px 0', boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Candidate Details'
            edit={edit}
            hideIcon={
              values.sjdCandidateInfo?.length == 0 ||
              !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_CANDIDATE_DETAILS)
            }
            click={() => {
              if (edit) {
                setSubmitted(true);
                formik.handleSubmit();
              } else {
                setEdit(!edit);
              }
            }}
          />
        )}
        <form
          onSubmit={(values) => {
            setSubmitted(true);
            formik.handleSubmit(values);
          }}>
          <div className='row'>
            <div className='field'>
              <InputLabel className='field-label' id='name'>
                Name *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.name}
                  fullWidth
                  id='name'
                  size='small'
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.name)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'name')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='email'>
                Email *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.email}
                  fullWidth
                  id='email'
                  size='small'
                  name='email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.email)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'email')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='phoneNumber'>
                Phone Number *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.phoneNumber}
                  fullWidth
                  id='phoneNumber'
                  size='small'
                  name='phoneNumber'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.phoneNumber)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'phoneNumber')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='location'>
                Location *
              </InputLabel>
              {edit ? (
                <LocationAutoComplete
                  onSelect={setLocationCallback}
                  initialValue={formik.values.location}></LocationAutoComplete>
              ) : (
                <span>{getLabel(formik.values.location)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'location')}</div>
            </div>
          </div>
          <div className='row'>
            <div className='field'>
              <InputLabel className='field-label' id='noticePeriod'>
                Notice Period (days) *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.noticePeriod}
                  fullWidth
                  id='noticePeriod'
                  size='small'
                  name='noticePeriod'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({ target: { name: 'noticePeriod', value: numericValue } });
                  }}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.noticePeriod)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'noticePeriod')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='experience'>
                Experience (years)*
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.experience}
                  fullWidth
                  id='experience'
                  size='small'
                  name='experience'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({ target: { name: 'experience', value: numericValue } });
                  }}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.experience)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'experience')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='skills'>
                Skills *
              </InputLabel>
              {edit ? (
                <div>
                  <Autocomplete
                    multiple
                    id='combo-box-demo'
                    options={skills}
                    sx={{ width: 300 }}
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id && option.id === value.id}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('skills', true, true)}
                    defaultValue={values?.skills ? values.skills : []}
                    renderInput={(params) => <TextField {...params} label='' />}
                    onChange={(_e, val) => formik.setFieldValue('skills', val)}
                  />
                </div>
              ) : (
                <Tooltip title={formik.values.skills?.map((s) => s.name).join(', ')}>
                  <span style={{ overflowWrap: 'anywhere' }}>
                    {getLabel(formik.values.skills?.map((s) => s.name).join(', '))}
                  </span>
                </Tooltip>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'skills')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='sourceChannel'>
                Source Channel *
              </InputLabel>
              {edit ? (
                <Select
                  size='small'
                  fullWidth
                  labelId='sourceChannel'
                  value={formik.values.sourceChannel}
                  name='sourceChannel'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id='demo-simple-select'>
                  <MenuItem disabled selected>
                    Select
                  </MenuItem>

                  {source === CANDIDATE_SOURCE.REFERRAL
                    ? globalObject.lookupvalues[LookupTypes.SOURCE_CHANNEL]
                        .filter((sc: any) => sc.name === CANDIDATE_SOURCE.REFERRAL)
                        .map((sourceChannel: any, index: number) => (
                          <MenuItem key={index} value={sourceChannel.id}>
                            {sourceChannel.name}
                          </MenuItem>
                        ))
                    : globalObject.lookupvalues[LookupTypes.SOURCE_CHANNEL].map(
                        (sourceChannel: any, index: number) => (
                          <MenuItem key={index} value={sourceChannel.id}>
                            {sourceChannel.name}
                          </MenuItem>
                        ),
                      )}
                </Select>
              ) : (
                <span>
                  {getLabel(getNameFromId(formik.values.sourceChannel, LookupTypes.SOURCE_CHANNEL))}
                </span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'sourceChannel')}</div>
            </div>
          </div>
          <div className='row'>
            <div className='field'>
              <InputLabel className='field-label' id='domain'>
                Domain
              </InputLabel>
              {edit ? (
                <Select
                  size='small'
                  fullWidth
                  labelId='domain'
                  value={formik.values.domain}
                  name='domain'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id='demo-simple-select'>
                  <MenuItem disabled selected>
                    Select
                  </MenuItem>
                  {globalObject.lookupvalues[LookupTypes.DOMAIN].map(
                    (domain: any, index: number) => (
                      <MenuItem key={index} value={domain.id}>
                        {domain.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              ) : (
                <span>{getLabel(getNameFromId(formik.values.domain, LookupTypes.DOMAIN))}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'domain')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='currentRole'>
                Current Role
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.currentRole}
                  fullWidth
                  id='currentRole'
                  size='small'
                  name='currentRole'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.currentRole)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'currentRole')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='currentCtc'>
                Current CTC
              </InputLabel>
              {edit ? (
                <div className='ctc-div'>
                  <TextField
                    value={
                      formik.values.currentCtc
                        ? formik.values.currentCtc.toLocaleString(ctcFormat)
                        : ''
                    }
                    fullWidth
                    id='currentCtc'
                    size='small'
                    name='currentCtc'
                    onChange={(e) => formatCtc(e.target.value, 'currentCtc')}
                    onBlur={formik.handleBlur}
                    className='current-ctc-input'
                    sx={{
                      width: '65%',
                    }}
                  />
                  <Tooltip
                    placement='top'
                    title={getNameFromId(formik.values.currentCtcFrequency, LookupTypes.PAY_TYPE)}>
                    <Select
                      size='small'
                      fullWidth
                      labelId='currentCtcFrequency'
                      sx={{ width: '35%' }}
                      value={formik.values.currentCtcFrequency}
                      className='current-ctc-select'
                      name='currentCtcFrequency'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      {globalObject.lookupvalues[LookupTypes.PAY_TYPE].map(
                        (domain: any, index: number) => (
                          <MenuItem key={index} value={domain.id}>
                            {domain.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Tooltip>
                </div>
              ) : (
                <span>
                  {isUserRole(ROLES.EVALUATOR, true) ? (
                    <>*****</>
                  ) : formik.values.currentCtc ? (
                    <>
                      {Number(formik.values.currentCtc).toLocaleString(ctcFormat)} (
                      {getNameFromId(formik.values.currentCtcFrequency, LookupTypes.PAY_TYPE)})
                    </>
                  ) : (
                    ''
                  )}
                </span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'currentCtc')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='expectedCtc'>
                Expected CTC
              </InputLabel>
              {edit ? (
                <div className='ctc-div'>
                  <TextField
                    value={
                      formik.values.expectedCtc
                        ? formik.values.expectedCtc.toLocaleString(ctcFormat)
                        : ''
                    }
                    fullWidth
                    id='expectedCtc'
                    size='small'
                    name='expectedCtc'
                    onChange={(e) => formatCtc(e.target.value, 'expectedCtc')}
                    onBlur={formik.handleBlur}
                    className='expected-ctc-input'
                    sx={{
                      width: '65%',
                    }}
                  />
                  <Tooltip
                    placement='top'
                    title={getNameFromId(formik.values.expectedCtcFrequency, LookupTypes.PAY_TYPE)}>
                    <Select
                      size='small'
                      fullWidth
                      labelId='expectedCtcFrequency'
                      sx={{ width: '35%' }}
                      value={formik.values.expectedCtcFrequency}
                      className='expected-ctc-select'
                      name='expectedCtcFrequency'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      {globalObject.lookupvalues[LookupTypes.PAY_TYPE].map(
                        (domain: any, index: number) => (
                          <MenuItem key={index} value={domain.id}>
                            {domain.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Tooltip>
                </div>
              ) : (
                <span>
                  {isUserRole(ROLES.EVALUATOR, true) ? (
                    <>*****</>
                  ) : formik.values.expectedCtc ? (
                    <>
                      {Number(formik.values.expectedCtc).toLocaleString(ctcFormat)} (
                      {getNameFromId(formik.values.expectedCtcFrequency, LookupTypes.PAY_TYPE)})
                    </>
                  ) : (
                    ''
                  )}
                </span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'expectedCtc')}</div>
            </div>
          </div>
          <div className='row'>
            <div className='field'>
              <InputLabel className='field-label' id='linkedInUrl'>
                LinkedIn Url
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.linkedInUrl}
                  fullWidth
                  id='linkedInUrl'
                  size='small'
                  name='linkedInUrl'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.linkedInUrl)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'linkedInUrl')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='visaType'>
                Visa Type
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.visaType}
                  fullWidth
                  id='visaType'
                  size='small'
                  name='visaType'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.visaType)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'visaType')}</div>
            </div>
            <div className='field'>
              <InputLabel className='field-label' id='visaStatus'>
                Visa Status
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.visaStatus}
                  fullWidth
                  id='visaStatus'
                  size='small'
                  name='visaStatus'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.visaStatus)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'visaStatus')}</div>
            </div>

            <div className='field'>
              <InputLabel className='field-label' id='visaValidity'>
                Visa Validity
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.visaValidity}
                  fullWidth
                  id='visaValidity'
                  size='small'
                  name='visaValidity'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.visaValidity)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'visaValidity')}</div>
            </div>
          </div>
        </form>
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginLeft: 3 }} onClick={previous} className='default-btn bg-btn'>
            Upload Another File
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => {
              setSubmitted(true);
              formik.handleSubmit();
            }}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default CandidateDetailsForm;
