/* eslint-disable react/prop-types */
import { MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface DataItem {
  name: string;
  value: number;
  url: string;
}

const JobsClosedByRecruiter = () => {
  const data: DataItem[] = [
    { name: 'Gopal Krishna', value: 8, url: 'https://example.com/gopal' },
    { name: 'Ramesh Pola', value: 6, url: 'https://example.com/gopal' },
    { name: 'Nagaraju Patri', value: 3, url: 'https://example.com/gopal' },
    { name: 'Rambabu Nola', value: 2, url: 'https://example.com/gopal' },
    { name: 'Bhavyasree Ramu', value: 5, url: 'https://example.com/gopal' },
    { name: 'Srikanth Adda', value: 1, url: 'https://example.com/gopal' },
    { name: 'Lavan Kumaran', value: 3, url: 'https://example.com/gopal' },
    { name: 'Ramesh Pola', value: 6, url: 'https://example.com/gopal' },
  ];

  const CustomLabel = (props: any) => {
    const { x, y, fill, value, width } = props;
    return (
      <text x={x + width + 10} y={y + 9} fontSize='14' fill={fill} textAnchor='start'>
        {value}
      </text>
    );
  };

  const RenderCustomYAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        fill='#007bff'
        textAnchor='end'
        cursor='pointer'
        onClick={() => window.open(payload.value.url, '_blank')}>
        {payload.value}
      </text>
    );
  };

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            src={require('../../assets/dashboard-assets/Group (1).png')}
            style={{ marginLeft: '20px', marginRight: '10px' }}
          />
          <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
            Jobs Closed By The Recruiter
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../assets/dashboard-assets/more_vert.png')}
            style={{ marginRight: '10px' }}
          />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0' }}></hr>
      <div style={{ display: 'flex', marginTop: '20px', marginBottom: '25px' }}>
        <div>
          <Select
            sx={{ width: '182px', height: '30px', marginRight: '10px', marginLeft: '26.61px' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'>
            <MenuItem key={0}>All Job Defintions</MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
        </div>
        <div>
          <Select
            sx={{ width: '135px', height: '30px', marginRight: '12px' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'>
            <MenuItem key={0}>All Recruiters</MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
          <span>Start Date</span>
          <DatePicker className='date' />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>End Date</span>
          <DatePicker className='date' />
        </div>
      </div>
      <ResponsiveContainer height='70%'>
        <BarChart
          className='bar'
          layout='vertical'
          data={data}
          margin={{
            right: 40,
            left: 20,
            bottom: 10,
          }}>
          <XAxis type='number' display='none' />
          <YAxis dataKey='name' type='category' width={150} tick={<RenderCustomYAxisTick />} />
          <Tooltip />
          <Bar label={<CustomLabel />} dataKey='value' fill='#007bff' barSize={7}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
export default JobsClosedByRecruiter;
