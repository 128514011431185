import { Add, Delete, Edit, Lock, LockOpen, Search } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  Select,
  Tooltip,
  Tab,
  Tabs,
  InputLabel,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getRole, toUpperFirstChar } from '../../common/commonUtil';
import { UserDetails } from '../../models/user.model';
import { getAllUsers, updateUser } from '../../service/userService';
import Notify from '../common/notify';
import AddUser from './add-user';
import { getNameFromId } from './../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import globalObject from './../../common/global-variables';
import UserPermissionTable from './user-permission-table';

const ManageUsers = () => {
  const [users, setUsers] = useState([] as any[]);
  const [rowData, setRowData] = useState([] as any[]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UserDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const USER_HEADERS = ['Name', 'Email', 'Operations', 'Role', 'Level', 'Action'];
  const [selectedRoleId, setSelectedRoleId] = useState(0);

  type tabType = 'ManageUsers' | 'RolePermission';
  const [selectedType, setSelectedType] = useState<tabType>('ManageUsers');

  useEffect(() => {
    fetchUsers();
  }, []);
  const requestSearch = (searchedVal: string) => {
    setPage(0);
    const filteredRows = users.filter((row) => {
      return Object.keys(row).some((k) =>
        String(row[k]).toLowerCase().includes(searchedVal.toLowerCase()),
      );
    });
    setRowData(filteredRows);
  };
  const filterRoles = (roleId: number) => {
    if (roleId === 0) {
      setRowData(users);
    } else {
      const filteredRoles: any[] = users.filter((candidate) => {
        return candidate.roles.map((e: any) => e.id).includes(roleId);
      });
      setRowData(filteredRoles);
    }
  };
  const handleNotificationClose = () => {
    setShowNotification(false);
  };
  const fetchUsers = () => {
    setLoading(true);
    getAllUsers()
      .then((resp) => {
        setUsers([...resp.data]);
        setRowData([...resp.data]);
        setLoading(false);
      })
      .catch((_err) => setUsers([]));
  };
  const handleEdit = (user: UserDetails) => {
    setSelectedUser(user);
    setIsEdit(true);
    setOpen(true);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleAddUser = () => {
    setSelectedUser({} as UserDetails);
    setIsEdit(false);
    setOpen(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLock = async (user: UserDetails) => {
    user.locked = !user.locked;
    await updateUser(user);
    fetchUsers();
  };
  const handleDelete = async (user: UserDetails) => {
    user.active = false;
    await updateUser(user);
    setOpenDeleteDialog(false);
    fetchUsers();
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const renderDialog = () => {
    return (
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure, you want to remove user?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{`User  ${selectedUser.firstName} will no longer have access to TAD`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={() => handleDelete({ ...selectedUser })} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleAddUserModalClose = (_event: any, reason: string) => {
    if (reason && reason != 'backdropClick') {
      fetchUsers();
      setShowNotification(true);
    }
    setOpen(false);
  };
  return (
    <div>
      <Paper sx={{ padding: '10px', margin: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 'bold', fontSize: 'large' }}>Users</span>
            <span>Invite or manage your users</span>
          </div>
          <div>
            <Tooltip title='Add user'>
              <Button
                className='default-btn bg-btn'
                style={{ height: '65%', display: 'flex', gap: '4px', textAlign: 'center' }}
                onClick={handleAddUser}>
                <Add style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
                <div>
                  <InputLabel sx={{ color: 'white' }}>Add Member</InputLabel>
                </div>
              </Button>
            </Tooltip>
            {open && (
              <AddUser user={selectedUser} isEdit={isEdit} handleClose={handleAddUserModalClose} />
            )}
          </div>
        </div>
      </Paper>
      <Paper sx={{ padding: '10px', margin: '10px' }}>
        <Box>
          <div
            style={{
              height: '38px',
              display: 'flex',
              borderBottom: '1px solid rgba(150, 150, 150,1)',
              justifyContent: 'space-between',
            }}>
            <Tabs
              className='sub-tabs'
              value={selectedType}
              onChange={(_e, v) => {
                setSelectedType(v);
              }}>
              <Tab
                className='sub-tab'
                style={selectedType == 'ManageUsers' ? { color: '#a72037' } : { color: 'black' }}
                label={'Manage Users'}
                value={'ManageUsers'}
              />
              <Tab
                className='sub-tab'
                style={selectedType == 'RolePermission' ? { color: '#a72037' } : { color: 'black' }}
                label={'Role & Permissions'}
                value={'RolePermission'}
              />
            </Tabs>
            <OutlinedInput
              sx={{ background: 'white', height: '30px' }}
              id='table-search'
              type='text'
              placeholder='Search...'
              onChange={(event) => requestSearch(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              }
            />
          </div>
          {selectedType == 'ManageUsers' ? (
            <div style={{ padding: '20px', marginTop: '10px' }}>
              {/* <Box>
                <h3>Manage Users</h3>
              </Box> */}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <Select
                    size='small'
                    displayEmpty
                    value={selectedRoleId}
                    onChange={(e: any) => {
                      setSelectedRoleId(e.target.value);
                      filterRoles(e.target.value);
                    }}
                    sx={{
                      background: 'white',
                      height: '35px',
                      marginBottom: 2,
                      width: '200px',
                    }}
                    renderValue={(selected) => {
                      return (
                        <p>
                          <FilterAltIcon
                            className='filter-Alt-Icon'
                            fontSize='small'
                            sx={{ marginRight: '10px', color: 'rgb(167, 32, 55)' }}
                          />
                          <span className='status'></span>
                          {selected === 0
                            ? 'Filter By: Role'
                            : toUpperFirstChar(getNameFromId(selected, LookupTypes.ROLE))}
                        </p>
                      );
                    }}>
                    <MenuItem value={0}>None</MenuItem>
                    {globalObject.lookupvalues[LookupTypes.ROLE].map(
                      (source: any, index: number) => (
                        <MenuItem key={index} value={source.id}>
                          {toUpperFirstChar(source.name)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {USER_HEADERS.map((userHeader) => (
                        <TableCell key={userHeader}>{userHeader}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rowData
                    ).map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {getNameFromId(user.operations, LookupTypes.OPERATION)}
                        </TableCell>
                        <TableCell>{getRole(user)}</TableCell>
                        <TableCell>{getNameFromId(user.level, LookupTypes.LEVEL)}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(user)}>
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpenDeleteDialog(true);
                              setSelectedUser(user);
                            }}>
                            <Delete />
                          </IconButton>

                          <IconButton onClick={() => handleLock({ ...user })}>
                            {user.locked ? (
                              <Tooltip title='Un-block user'>
                                <Lock sx={{ color: '#DC4C64' }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title='Block user'>
                                <LockOpen />
                              </Tooltip>
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        count={rowData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='pagination'
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color='inherit' />
              </Backdrop>
              <Notify severity='success' open={showNotification} onClose={handleNotificationClose}>
                <span>User added/updated successfully!</span>
              </Notify>
              {openDeleteDialog && renderDialog()}
            </div>
          ) : (
            <UserPermissionTable />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default ManageUsers;
