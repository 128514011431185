import { Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import globalObject from '../../../common/global-variables';
import React, { useEffect, useState } from 'react';
import {
  getInitials,
  isPermissions,
  isUserRole,
  isUserRoleIn,
  toUpperFirstChar,
} from '../../../common/commonUtil';
import './app-header.scss';
import { MODULE, ROLES } from '../../../constants/common';
import useNavigate from '../../../common/useNavigate';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationBanner from '../notification-banner';
import { getNotification } from '../../../service/dashboardService';

const AppHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [parentPath, setParentPath] = React.useState('');
  const [msg, setMsg] = useState('');
  const closeUserMenu = () => {
    setAnchorEl(null);
  };
  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshtoken');
    redirect('/login');
  };
  const getRoles = (): string => {
    return globalObject.userObject?.roles
      ?.map((role: any): string => toUpperFirstChar(role.name))
      .join(' | ');
  };
  const [navigate, redirect] = useNavigate();

  const tabs = [
    {
      label: 'Dashboard',
      link: '/dashboard',
      module: MODULE.DASHBOARD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
    },
    {
      label: 'Job Definitions',
      link: '/source-job-definition',
      module: MODULE.SJD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'Questionnaire',
      link: '/questionnaire',
      module: MODULE.QUESTIONNAIRE,
      roles: [ROLES.ADMIN, ROLES.LEAD],
    },
    {
      label: 'Reporting',
      link: '/reporting',
      module: MODULE.REPORTING,
      roles: [ROLES.ADMIN, ROLES.LEAD],
    },
    {
      label: 'Candidate Search',
      link: '/candidate-search',
      module: MODULE.CANDIDATE_SEARCH,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'Employee Referral',
      link: '/employee-referral',
      module: MODULE.EMPLOYEE_REFERRAL,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
    },
    {
      label: 'Applicants',
      link: '/applicants',
      module: MODULE.APPLICANTS,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'LMS',
      link: '/lms',
      module: MODULE.LMS,
      roles: [ROLES.INTERN, ROLES.MANAGER, ROLES.ADMIN],
    },
  ];

  const filteredTabs = tabs.filter((tab) => isPermissions(tab.module));

  const handleMenuClick = (tab: any) => {
    navigate(
      [
        {
          ...tab,
        },
      ],
      true,
    );
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(
        [
          isUserRoleIn([ROLES.INTERN, ROLES.MANAGER])
            ? {
                label: 'LMS',
                link: '/lms',
              }
            : {
                label: `${filteredTabs[0].label}`,
                link: `${filteredTabs[0].link}`,
              },
        ],
        true,
      );
    }
    getNotification()
      .then((resp) => {
        const response = resp.data.map((data: any) => data.message);
        setStringMsg(response);
      })
      .catch((_err) => setMsg('Something went wrong'));
  }, []);

  useEffect(() => {
    getParentPath(location.pathname);
  }, [location.pathname]);

  function getParentPath(path: string) {
    const parent = path.slice(1).split('/')[0];
    setParentPath((_prev) => `/${parent}`);
  }

  const setStringMsg = (text: any[]) => {
    setMsg(
      text
        .map((message: any) => {
          return `${message}${'&nbsp;'.repeat(20)}`;
        })
        .join(''),
    );
  };

  const handleNotification = () => {
    setMsg('');
  };

  return (
    <>
      {msg !== '' && msg !== undefined && !isUserRole(ROLES.EVALUATOR, true) && (
        <div style={{ display: 'flex', height: '19px' }}>
          <div style={{ width: '100%' }}>
            <NotificationBanner msg={msg}></NotificationBanner>
          </div>
          <div>
            <CloseIcon
              fontSize='small'
              sx={{
                backgroundColor: 'rgb(0,0,0,.7)',
                color: 'white',
                height: '19px',
                cursor: 'pointer',
              }}
              onClick={handleNotification}
            />
          </div>
        </div>
      )}
      <div className='nav-bar' id='nav-bar'>
        <div className='left-div'>
          <a href={`${filteredTabs[0].link}`} className='logo-anchor'>
            <div className='logo'>
              {/* <img className='icon' src={require('../../../assets/logo.png')} alt='logo-img' /> */}
              TAD
            </div>
          </a>
          {filteredTabs.map(
            (tab, index) =>
              // (!tab.roles || isUserRoleIn(tab.roles)) && (
              isPermissions(tab.module) && (
                <div
                  key={index}
                  className={
                    parentPath == tab.link
                      ? 'nav-menu-item nav-menu-item-selected'
                      : 'nav-menu-item'
                  }
                  onClick={() => handleMenuClick(tab)}>
                  <a>{tab.label}</a>
                </div>
              ),
          )}
        </div>
        <div className='right-div'>
          <div className='user-name'>
            <p>
              {globalObject.userObject.firstName} {globalObject.userObject.lastName}
            </p>
            <p className='role'>{getRoles()}</p>
          </div>
          <div className='avatar'>
            <div className='user-icon' onClick={handleUserMenu}>
              {getInitials(globalObject.userObject)}
            </div>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={closeUserMenu}
              disableAutoFocusItem>
              <MenuItem
                onClick={() => {
                  redirect('/settings');
                  closeUserMenu();
                }}>
                <SettingsIcon sx={{ paddingRight: '10px' }} />
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <PowerSettingsNewIcon sx={{ paddingRight: '10px' }} />
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
