import { Paper } from '@mui/material';
import '../../../tad-revamp-ui/theme/variables.scss';
// import { DatePicker } from '@mui/x-date-pickers';
// import CommonMenu from './common-menu';
import UpCommingInterviews from './up-comming-interviews';
import ToDo from './to-do';
import HiringPipeLine from './hiring-pipe-line';
import SjdProgress from './sjd-progress';
import RecentJobDefinitions from './recent-job-definitions';
import Analytics from './analytics/analytics';
import ProfilerRatingAndAudit from './profiler-rating-and-audit';
import JobsClosedByRecruiter from './jobs-closed-by-recruiter';
import RecruiterPerformance from './recruiter-performance';
import './new-dashboard.scss';
import NotificationBar from './notification-bar';

const NewDashboard = () => {
  return (
    <div className='main-div'>
      <div className='dashboard-header'>
        <span className='heading'>Hello Emma, Good Morning!</span>
      </div>
      <div>
        <NotificationBar />
      </div>
      <div className='dashboard-container'>
        <div style={{ flex: '1' }}>
          <UpCommingInterviews />
        </div>
        <div style={{ flex: '1' }}>
          <ToDo />
        </div>
        <div style={{ flex: '1' }}>
          <HiringPipeLine />
        </div>
      </div>
      <div className='dashboard-container'>
        <div style={{ width: '67%' }}>
          <Paper className='paper' sx={{ height: '26.125rem', width: '100%' }}>
            <SjdProgress />
          </Paper>
        </div>
        <div style={{ width: '33%' }}>
          <Paper className='paper' sx={{ height: '26.125rem', width: '100%' }}>
            <RecentJobDefinitions />
          </Paper>
        </div>
      </div>
      <div className='dashboard-container'>
        <div style={{ width: '67%' }}>
          <Paper className='paper' sx={{ height: '100%' }}>
            <Analytics />
          </Paper>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '33%' }}>
          <div style={{ height: '50%' }}>
            <Paper className='right-paper' sx={{ height: '100%' }}>
              <ProfilerRatingAndAudit />
            </Paper>
          </div>
          <div style={{ height: '50%' }}>
            <Paper className='right-paper' sx={{ height: '100%' }}>
              <JobsClosedByRecruiter />
            </Paper>
          </div>
        </div>
      </div>
      <div className='dashboard-container'>
        <div style={{ width: '66.2%' }}>
          <Paper className='paper'>
            <RecruiterPerformance />
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default NewDashboard;
