export enum LookupTypes {
  QUALIFICATION = 'qualification',
  PRIORITY = 'priority',
  PAY_TYPE = 'payType',
  JOB_TYPE = 'jobType',
  DOMAIN = 'domain',
  COMPANY = 'company',
  DEPARTMENT = 'department',
  OPERATION = 'operation',
  ROLE = 'role',
  SOURCE_CHANNEL = 'sourceChannel',
  CANDIDATE_STATUS = 'candidateStatus',
  SJD_STATUS = 'sjdStatus',
  CANDIDATE_EVENT_TYPE = 'candidateEventType',
  SJD_EVENT_TYPE = 'sjdEventType',
  CLIENT = 'client',
  ANSWER_TYPE = 'answerType',
  QUESTIONNAIRE_TYPE = 'questionnaireType',
  HIRING_MANAGER = 'hiringManager',
  PROVIDER = 'provider',
  TECH_STACK = 'techstack',
  LEVEL = 'level',
  PERMISSION_TYPE = 'permissionType',
  MODULE = 'module',
  SUBMODULE = 'subModule',
}
