import { MenuItem, Paper, Select, styled } from '@mui/material';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';

const HiringPipeLine = () => {
  // const data = [
  //   { name: 'Total Candidates', value: '330', color: '#9D5BF3' },
  //   { name: 'New', value: '77', color: '#F1D87E' },
  //   { name: 'Profiling', value: '33', color: '#7AE08B' },
  //   { name: 'Interview Scheduled', value: '28', color: '#8CD6FF' },
  //   { name: 'HR Discussion', value: '14', color: '#FF5959' },
  //   { name: 'Document Verification ', value: '13', color: '#C95B5B' },
  //   { name: 'Offer', value: '08', color: '#3E78FD' },
  //   { name: 'Joined', value: '06', color: '#999999' },
  // ];

  const data = [
    { value: 5, label: 'A' },
    { value: 5, label: 'B' },
    { value: 10, label: 'C' },
    { value: 2, label: 'D' },
    { value: 10, label: 'E' },
    { value: 10, label: 'F' },
    { value: 15, label: 'G' },
    { value: 70, label: 'H' },
  ];

  const size = {
    width: 400,
    height: 200,
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));
  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'flex' }}>
            <img
              src={require('../../assets/dashboard-assets/assessment.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
            <span className='heading' style={{ height: '24px', fontSize: '18px', fontWeight: 600 }}>
              Hiring Pipeline
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={require('../../assets/dashboard-assets/more_vert.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
          </div>
        </div>

        <hr style={{ color: '#C1C0C0' }}></hr>
        <div style={{ display: 'flex', marginTop: '20px', marginBottom: '30px' }}>
          <div>
            <Select
              sx={{ width: '182px', height: '30px', marginRight: '10px', marginLeft: '26.61px' }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'
              value={0}>
              <MenuItem key={0} value={0}>
                All Job Defintions
              </MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
          </div>
          <div>
            <Select
              sx={{ width: '135px', height: '30px', marginRight: '12px' }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'
              value={0}>
              <MenuItem key={0} value={0}>
                All Recruiters
              </MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
            <span>Start Date</span>
            <DatePicker className='date' />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>End Date</span>
            <DatePicker className='date' />
          </div>
        </div>

        <div
          style={{
            margin: '52px 28px 46px',
          }}>
          <PieChart series={[{ data, innerRadius: 65 }]} {...size}>
            <PieCenterLabel>63</PieCenterLabel>
          </PieChart>
        </div>
      </Paper>
    </>
  );
};
export default HiringPipeLine;
