import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
  Menu,
  Checkbox,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LookupTypes } from '../../common/lookupTypes';
import globalObject from '../../common/global-variables';
import { toUpperFirstChar } from '../../common/commonUtil';
import { getPermissionsForUser, postPermissions } from '../../service/RolesAndPermissionsService';

interface PermissionValue {
  read: number;
  write: number;
  delete: number;
}

interface Permission {
  id: number;
  value: PermissionValue;
  subModules: any;
}

interface PermissionsData {
  [moduleName: string]: Permission;
}

interface CheckedState {
  [key: string]: boolean;
}

const UserPermissionTable = () => {
  const recruiter: any = localStorage.getItem('role') ? localStorage.getItem('role') : 'admin';
  const [selectedRole, setSelectedRole] = useState(recruiter);
  const [moduleName, setModuleName] = useState('');
  const [permissionsData, setPermissionsData] = useState<PermissionsData>(
    globalObject.permissions[selectedRole] || [],
  );
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [checkedState, setCheckedState] = useState<CheckedState>({});
  const [checkedStateWrite, setCheckedStateWrite] = useState<CheckedState>({});
  const [checkedStateDelete, setCheckedStateDelete] = useState<CheckedState>({});

  const handleCheckboxChange = (subModuleId: string, isChecked: boolean, type: string) => {
    if (type == 'read') {
      setCheckedState((prevState) => ({
        ...prevState,
        [subModuleId]: isChecked,
      }));
    } else if (type == 'write') {
      setCheckedStateWrite((prevState) => ({
        ...prevState,
        [subModuleId]: isChecked,
      }));
    } else if (type == 'delete') {
      setCheckedStateDelete((prevState) => ({
        ...prevState,
        [subModuleId]: isChecked,
      }));
    }
  };

  useEffect(() => {
    localStorage.setItem('role', selectedRole);
  }, [selectedRole]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, module: any) => {
    setAnchorEl(event.currentTarget);
    setModuleName(module);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePermissionChange = (
    moduleName: string,
    type: keyof PermissionValue,
    value: number,
  ) => {
    setPermissionsData((prevPermissions: any) => {
      const updatedPermissions: PermissionsData = {
        ...prevPermissions,
        [moduleName]: {
          ...prevPermissions[moduleName],
          value: {
            ...prevPermissions[moduleName].value,
            [type]: value,
          },
        },
      };
      const previousPermissions = prevPermissions;
      setLoading(true);
      postPermissions(updatedPermissions, selectedRole, null, null, null)
        .then((_res) => {
          refreshGlobalObject();
        })
        .catch(() => {
          setPermissionsData(previousPermissions);
          setLoading(false);
        });

      return updatedPermissions;
    });
  };

  useEffect(() => {
    const rolePermissions = globalObject.permissions[selectedRole] || [];
    setPermissionsData(rolePermissions);
  }, [selectedRole]);

  const refreshGlobalObject = () => {
    getPermissionsForUser()
      .then((res) => {
        globalObject.permissions = res.data;
        const permission = res.data;
        setPermissionsData(permission[selectedRole]);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ gap: '1rem' }}>
            <Typography sx={{ marginBottom: '10px' }}>Select Role</Typography>
            <Select
              value={selectedRole}
              onChange={(e: any) => {
                setSelectedRole(e.target.value);
                setCheckedState({});
                setCheckedStateDelete({});
                setCheckedStateWrite({});
              }}
              displayEmpty
              sx={{ minWidth: 150 }}>
              <MenuItem value='' disabled selected>
                Roles
              </MenuItem>
              {globalObject.lookupvalues[LookupTypes.ROLE].map((role: any) => (
                <MenuItem key={role.id} value={role.name}>
                  {toUpperFirstChar(role.name)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Button sx={{ marginTop: '30px', marginLeft: '100px' }}>
            <Add />
            <Typography>Add Role</Typography>
          </Button>
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Features</TableCell>
            <TableCell>Read</TableCell>
            <TableCell>Write</TableCell>
            <TableCell>Delete</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {globalObject.lookupvalues[LookupTypes.MODULE].map((module: any) => {
            const permission = permissionsData[module.name];
            return (
              <TableRow key={module.id}>
                <TableCell>
                  <div>
                    <div>{module.name}</div>
                  </div>
                </TableCell>
                <TableCell key={module.id}>
                  <Select
                    value={permission?.value?.read || 4}
                    disabled={!permission}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'read', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].map((permType: any) => (
                      <MenuItem key={permType.id} value={permType.id}>
                        {toUpperFirstChar(permType.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    disabled={!permission}
                    value={permissionsData[module.name]?.value?.write || 4}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'write', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].map((permType: any) => (
                      <MenuItem key={permType.id} value={permType.id}>
                        {toUpperFirstChar(permType.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    disabled={!permission}
                    value={permissionsData[module.name]?.value?.delete || 4}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'delete', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].map((permType: any) => (
                      <MenuItem key={permType.id} value={permType.id}>
                        {toUpperFirstChar(permType.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-controls='simple-menu'
                    onClick={(e) => {
                      handleClick(e, module.name);
                    }}
                    size='small'>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Menu
        sx={{
          maxHeight: '300px',
          overflow: 'visible',
          maxWidth: '300px',
        }}
        anchorEl={anchorEl}
        id='simple-menu'
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem disabled sx={{ fontSize: 'small' }}>
          Sub Modules :{' '}
        </MenuItem>
        {permissionsData[moduleName]?.subModules.length == 0 && (
          <MenuItem sx={{ fontSize: 'small' }} disabled>
            No Sub Modules
          </MenuItem>
        )}
        {permissionsData[moduleName]?.subModules.length > 0 &&
          permissionsData[moduleName]?.subModules?.map((subModule: any, index: number) => (
            <MenuItem
              key={index}
              value={subModule?.subModuleId.id}
              sx={{ fontSize: 'small', width: '100%', minWidth: '350px' }}>
              <div>
                <span style={{ width: '50%' }}>{subModule?.subModuleId.name}</span>
                <div style={{ width: '50%' }}>
                  Read:
                  <Checkbox
                    onChange={(e) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'read');

                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        e.target.checked ?? false,
                        subModule?.write ?? false,
                        subModule?.delete ?? false,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedState[subModule?.subModuleId.id] ?? subModule?.isActive ?? false
                    }
                    style={{ color: '#a72037' }}
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                  Write:
                  <Checkbox
                    onChange={(e) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'write');
                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        subModule?.isActive ?? false,
                        e.target.checked ?? false,
                        subModule?.delete ?? false,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedStateWrite[subModule?.subModuleId.id] ?? subModule?.write ?? false
                    }
                    style={{ color: '#a72037' }}
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                  Delete:
                  <Checkbox
                    onChange={(e) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'delete');
                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        subModule?.isActive ?? false,
                        subModule?.write ?? false,
                        e.target.checked ?? false,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedStateDelete[subModule?.subModuleId.id] ?? subModule?.delete ?? false
                    }
                    style={{ color: '#a72037' }}
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                </div>
              </div>
            </MenuItem>
          ))}
      </Menu>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default UserPermissionTable;
