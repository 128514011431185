import {
  Avatar,
  Chip,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const RecentJobDefinitions = () => {
  const data = [
    {
      sjd: { id: 'TAD1490', name: 'Product Manager' },
      value: '01',
      recruiters: '',
      status: 'In Progress',
    },
    {
      sjd: { id: 'TAD1490', name: 'PHP Developer' },
      value: '04',
      recruiters: '',
      status: 'In Progress',
    },
  ];
  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            src={require('../../assets/dashboard-assets/Group (1).png')}
            style={{ marginLeft: '20px', marginRight: '10px' }}
          />
          <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
            Recent Job Definitions
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../assets/dashboard-assets/more_vert.png')}
            style={{ marginRight: '10px' }}
          />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0' }}></hr>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}>
        <div>
          <Select
            sx={{
              display: 'flex',
              width: '8.4375rem',
              height: '30px',
              // marginRight: '12px',
              marginLeft: '26.61px',
            }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            value={0}
            id='demo-simple-select'>
            <MenuItem key={0} value={0}>
              All Recruiters
            </MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
        </div>
        <span
          style={{
            fontSize: '1rem',
            color: '#1976D2',
            fontWeight: '400',
            marginRight: '20px',
            textDecoration: 'underline',
          }}>
          View All
        </span>
      </div>
      <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
        <Table>
          <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
            <TableRow>
              <TableCell>Job Definition</TableCell>
              <TableCell>Candidates</TableCell>
              <TableCell>Recruiters</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ height: '0.62rem', background: 'transparent', padding: 0 }}>
              <TableCell colSpan={4} style={{ padding: 0, border: 'none' }} />
            </TableRow>
            <TableRow
              style={{
                width: '43.875rem',
                height: '4.875rem',
                background: '#F1F4F8',
                padding: 0,
              }}>
              <TableCell>
                <span
                  style={{
                    color: '#868686',
                    fontSize: '0.875rem',
                    display: 'flex',
                    fontWeight: '400',
                  }}>
                  {data[0].sjd.id}
                </span>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: '#1976D2',
                  }}>
                  {data[0].sjd.name}
                </span>
              </TableCell>
              <TableCell
                style={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'underline',
                  color: '#1976D2',
                }}>
                {data[0].value}
              </TableCell>
              <TableCell>
                <Avatar alt={data[0].sjd.name} src='/static/images/avatar/1.jpg'></Avatar>
                {data[0].recruiters}
              </TableCell>
              <TableCell
                style={{
                  padding: 0,
                  marginTop: '1.69rem',
                  marginBottom: '1.31rem',
                  marginRight: '0.78rem',
                }}>
                <Chip
                  label={data[0].status}
                  style={{
                    backgroundColor: '#F1D87E',
                    fontSize: '0.875rem',
                    fontWeight: '600',
                    height: '1.875rem',
                    width: 'auto',
                    padding: '0 8px',
                    borderRadius: '0.3125rem',
                    textOverflow: 'clip',
                    overflow: 'visible',
                    whiteSpace: 'nowrap',
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow style={{ height: '0.62rem', background: 'transparent', padding: 0 }}>
              <TableCell colSpan={4} style={{ padding: 0, border: 'none' }} />
            </TableRow>
            <TableRow
              style={{
                width: '43.875rem',
                height: '4.875rem',
                background: '#F1F4F8',
                padding: 0,
              }}>
              <TableCell>
                <span
                  style={{
                    color: '#868686',
                    fontSize: '0.875rem',
                    display: 'flex',
                    fontWeight: '400',
                  }}>
                  {data[1].sjd.id}
                </span>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: '#1976D2',
                  }}>
                  {data[1].sjd.name}
                </span>
              </TableCell>
              <TableCell
                style={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'underline',
                  color: '#1976D2',
                }}>
                {data[1].value}
              </TableCell>
              <TableCell>
                <Avatar alt={data[1].sjd.name} src='/static/images/avatar/2.jpg' />
                {data[1].recruiters}
              </TableCell>
              <TableCell
                style={{
                  padding: 0,
                  marginTop: '1.69rem',
                  marginBottom: '1.31rem',
                  marginRight: '0.78rem',
                }}>
                <Chip
                  label={data[1].status}
                  style={{
                    backgroundColor: '#F1D87E',
                    fontSize: '0.875rem',
                    fontWeight: '600',
                    height: '1.875rem',
                    width: 'auto',
                    padding: '0 8px',
                    borderRadius: '0.3125rem',
                    textOverflow: 'clip',
                    overflow: 'visible',
                    whiteSpace: 'nowrap',
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default RecentJobDefinitions;
