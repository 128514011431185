import {
  Avatar,
  Backdrop,
  Box,
  Divider,
  Drawer,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import './new-app-header.scss';
import { useEffect, useState } from 'react';
import { MODULE, ROLES } from '../../../../constants/common';
import dashboardIcon from '../../../assets/dashboard-assets/app-bar-dashboard.png';
import sjdIcon from '../../../assets/dashboard-assets/app-bar-sjd.png';
import candidatesIcon from '../../../assets/dashboard-assets/app-bar-candidates.png';
import reportsIcon from '../../../assets/dashboard-assets/app-bar-reports.png';
import editIcon from '../../../assets/dashboard-assets/edit.png';
import signOutIcon from '../../../assets/dashboard-assets/exit_to_app.png';
import employeesIcon from '../../../assets/dashboard-assets/app-bar-employees.png';
import lmsIcon from '../../../assets/dashboard-assets/app-bar-lms.png';
import settingsIcon from '../../../assets/dashboard-assets/app-bar-settings.png';
import emailIcon from '../../../assets/dashboard-assets/email.png';
import tadLogo from '../../../assets/login/tad-logo.png';
import useNavigate from '../../../../common/useNavigate';
import { DatePicker } from '@mui/x-date-pickers';
import CommonMenu from '../../dashboard/common-menu';
import { isPermissions, isUserRole, redirectToLogin } from '../../../../common/commonUtil';

interface Job {
  id: string;
  title: string;
}

const NewAppHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorE1, setProfileAnchorE1] = useState<null | HTMLElement>(null);
  const [profileFlag, setProfileFlag] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const jobs: Job[] = [
    { id: 'product-manager', title: 'Product Manager' },
    { id: 'enior-php-developer', title: 'Senior PHP Developer' },
    { id: 'devops-engineer', title: 'DevOps Engineer' },
    { id: 'ux-designer', title: 'UX Designer' },
    { id: 'graphic-designer', title: 'Graphic Designer' },
  ];

  const tabs = [
    {
      label: 'DASHBOARD',
      link: '/dashboard',
      module: MODULE.DASHBOARD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
      icon: dashboardIcon,
    },
    {
      label: 'JOB DEFINITIONS',
      link: '/source-job-definition',
      module: MODULE.SJD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
      icon: sjdIcon,
    },
    {
      label: 'CANDIDATES',
      link: '/questionnaire',
      module: MODULE.QUESTIONNAIRE,
      roles: [ROLES.ADMIN, ROLES.LEAD],
      icon: candidatesIcon,
    },
    {
      label: 'REPORTS',
      link: '/reporting',
      module: MODULE.REPORTING,
      roles: [ROLES.ADMIN, ROLES.LEAD],
      icon: reportsIcon,
    },
    {
      label: 'EMPLOYEES',
      link: '/candidate-search',
      module: MODULE.CANDIDATE_SEARCH,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
      icon: employeesIcon,
    },
    {
      label: 'REFERRALS',
      link: '/employee-referral',
      module: MODULE.EMPLOYEE_REFERRAL,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
      icon: candidatesIcon,
    },
    {
      label: 'LMS',
      link: '/applicants',
      module: MODULE.APPLICANTS,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
      icon: lmsIcon,
    },
    {
      label: 'SETTINGS',
      link: '/settings',
      module: MODULE.LMS,
      roles: [ROLES.INTERN, ROLES.MANAGER, ROLES.ADMIN],
      icon: settingsIcon,
    },
  ];

  const DrawerList = (
    <Box role='presentation' sx={{ height: '100%' }} onClick={() => setMenuOpen(false)}>
      <List sx={{ padding: '2.063rem 0rem 0rem 2.06rem' }}>
        <ListItem disablePadding sx={{ marginBottom: '2rem', cursor: 'pointer', fontWeight: 400 }}>
          <ListItemIcon>
            <img
              style={{ width: '1.5rem', height: '1.5rem', flexShrink: '0px' }}
              src={require('../../../assets/dashboard-assets/close.png')}
              alt='Search Icon'
            />
          </ListItemIcon>
          <ListItemText primary={'MENU'} />
        </ListItem>
        {tabs.map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ marginBottom: '2rem', cursor: 'pointer', fontWeight: 400 }}
            onClick={() => handleMenuClick(text)}>
            <ListItemIcon>
              <img
                style={{ width: '1.5rem', height: '1.5rem', flexShrink: '0px' }}
                src={text.icon}
                alt='Search Icon'
              />
            </ListItemIcon>
            <ListItemText primary={text.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
  const [navigate] = useNavigate();

  const handleMenuClick = (tab: any) => {
    navigate(
      [
        {
          ...tab,
        },
      ],
      true,
    );
  };

  const handleSwitch = (val: any) => {
    localStorage.setItem('switch', val);
    setShowOverlay(true);
    setProfileFlag(false);
    setTimeout(() => {
      redirectToLogin();
    }, 2000);
  };

  const filteredTabs = tabs.filter((tab) => isPermissions(tab.module));

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(
        [
          isUserRole(ROLES.INTERN)
            ? {
                label: 'LMS',
                link: '/lms',
              }
            : {
                label: `${filteredTabs[0].label}`,
                link: `${filteredTabs[0].link}`,
              },
        ],
        true,
      );
    }
    // getNotification()
    //   .then((resp) => {
    //     const response = resp.data.map((data: any) => data.message);
    //     setStringMsg(response);
    //   })
    //   .catch((_err) => setMsg('Something went wrong'));
  }, []);

  return (
    <div className='app-bar-container'>
      <div className='app-bar'>
        <div
          className='left-div'
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1.17rem',
            gap: '1.87rem',
            flexWrap: 'wrap',
          }}>
          <img
            src={require('../../../assets/dashboard-assets/Vector.png')}
            style={{
              cursor: 'pointer',
              width: '1.3rem',
              height: '1.35188rem',
            }}
            onClick={() => setMenuOpen(true)}
          />
          <img
            src={require('../../../assets/dashboard-assets/Logo.png')}
            style={{
              width: '5.06069rem',
              height: '2.75rem',
              flexShrink: 0,
            }}
          />
        </div>
        <Drawer
          className='drawer'
          sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}>
          {DrawerList}
        </Drawer>
        <div
          className='right-div'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2.19rem',
          }}>
          <div style={{ display: 'flex', gap: '2.19rem' }}>
            <img
              src={require('../../../assets/dashboard-assets/Search icon (1).png')}
              className='img'
              onClick={() => setFilterOpen(!filterOpen)}
            />
            <img
              src={require('../../../assets/dashboard-assets/Vector (4).png')}
              className='img'
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setFlag(true);
              }}
            />
            <img
              src={require('../../../assets/dashboard-assets/Vector (1).png')}
              className='img'
              onClick={() =>
                handleMenuClick({
                  label: 'REFERRALS',
                  link: '/settings',
                })
              }
            />
            <img src={require('../../../assets/dashboard-assets/Vector (2).png')} className='img' />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              marginRight: '20.31px',
              textAlign: 'end',
            }}>
            <div>
              <p style={{ fontSize: '16px', fontWeight: 400, color: 'white', margin: '0' }}>Emma</p>
              <p style={{ fontSize: '16px', fontWeight: 600, color: 'white', margin: '0' }}>
                Recruitment Manager
              </p>
            </div>
            <div
              onClick={(event) => {
                setProfileAnchorE1(event.currentTarget);
                setProfileFlag(true);
              }}>
              <Avatar
                alt='Remy Sharp'
                sx={{ width: '1.875rem', height: '1.875rem' }}
                src={require('../../../../assets/user-icon.png')}
              />
            </div>
          </div>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            className='menu-list'
            anchorEl={anchorEl}
            open={flag}
            onClose={() => setFlag(false)}>
            <MenuItem>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={sjdIcon}
                alt='Search Icon'
              />{' '}
              Create a New Job
            </MenuItem>
            <MenuItem>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={candidatesIcon}
                alt='Search Icon'
              />{' '}
              Add a Candidate
            </MenuItem>
            <MenuItem>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={emailIcon}
                alt='Search Icon'
              />{' '}
              Create a New Template
            </MenuItem>
            <MenuItem>
              <img
                style={{ flexShrink: '0px', color: 'blue', marginRight: '0.7rem' }}
                src={require('../../../assets/dashboard-assets/add (1).png')}
                alt='Search Icon'
              />
              Add Activity
            </MenuItem>
          </Menu>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            className='app-list'
            anchorEl={profileAnchorE1}
            open={profileFlag}
            onClose={() => setProfileFlag(false)}>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '1.75rem' }}>
              <div>
                <Avatar
                  alt='Remy Sharp'
                  sx={{ width: '3.125rem', height: '3.09619rem' }}
                  src={require('../../../../assets/user-icon.png')}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span className='menuItem'>Emma Johnson</span>
                <span style={{ fontWeight: 600, fontSize: 'var(--Font-size-P, 1rem)' }}>
                  Recruiter
                </span>
                <a
                  style={{ fontSize: 'var(--Font-size-P, 1rem)' }}
                  href={'emma.johnson@peopletech.com'}>
                  emma.johnson@peopletech.com
                </a>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.75rem',
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Primary-Dark-grey-500, #333)',
                  }}>
                  Switch to US Operations
                </span>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Secondary-colors-Grey, #868686)',
                  }}>
                  You cannot access India Operations data
                </span>
              </div>
              <div>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  defaultChecked
                  // onChange={(e) => handleSwitch(e.target.checked)}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.75rem',
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Primary-Dark-grey-500, #333)',
                  }}>
                  Switch to New Classic TAD
                </span>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Secondary-colors-Grey, #868686)',
                  }}>
                  You can still switch back to old TAD
                </span>
              </div>
              <div>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  defaultChecked
                  onChange={(e) => handleSwitch(e.target.checked)}
                />
              </div>
            </div>
            <div
              style={{
                marginBottom: '1.5rem',
              }}>
              <hr style={{ color: '#E6E7E8)', margin: 0 }} />
            </div>
            <MenuItem sx={{ marginBottom: '0.5rem' }}>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={editIcon}
                alt='Search Icon'
              />{' '}
              <span className='menuItem'>Edit Profile</span>
            </MenuItem>
            <MenuItem sx={{ marginBottom: '0.5rem' }}>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={settingsIcon}
                alt='Search Icon'
              />{' '}
              <span className='menuItem'>Account Settings</span>
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuClick({
                  label: 'Login',
                  link: '/login',
                })
              }>
              <img
                style={{ flexShrink: '0px', marginRight: '0.7rem' }}
                src={signOutIcon}
                alt='Search Icon'
              />{' '}
              <span className='menuItem'>Sign Out</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
      {filterOpen && (
        <div className='new-dashboard-menu'>
          <div style={{ width: '41.04%', display: 'flex', alignItems: 'center' }}>
            <InputBase
              sx={{ ml: '19px', width: '749px' }}
              placeholder='Search a keyword'
              inputProps={{ 'aria-label': 'Search a keyword' }}
            />
            <div>
              <img
                style={{ width: '1.25rem', height: '1.25rem', flexShrink: '0px' }}
                src={require('../../../assets/dashboard-assets/Search icon.png')}
                alt='Search Icon'
              />
            </div>
            <hr style={{ marginLeft: '16px', color: '#C1C0C0', height: '28px' }} />
          </div>
          <div
            style={{
              width: '58.96%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: '-27px',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px' }}>All Job Definitions</span>
              <CommonMenu list={jobs} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px' }}>All Operations</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Recruiters</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Locations</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Clients</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Start Date</span>
              <DatePicker className='date' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>End Date</span>
              <DatePicker className='date' />
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}
        onClick={() => setShowOverlay(false)}>
        <div className='loader-container'>
          <div className='loader-line'>
            <img src={tadLogo} alt='Loading' className='loader-image' />
          </div>
        </div>
      </Backdrop>
    </div>
  );
};
export default NewAppHeader;
