import { IconButton, Paper } from '@mui/material';

const ToDo = () => {
  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'flex' }}>
            <img
              src={require('../../assets/dashboard-assets/check_circle_outline.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
            <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
              To Do
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <img
                src={require('../../assets/dashboard-assets/add.png')}
                style={{ marginLeft: '20px', marginRight: '10px' }}
              />
            </IconButton>
            <img
              src={require('../../assets/dashboard-assets/more_vert.png')}
              style={{ marginLeft: '20px', marginRight: '10px' }}
            />
          </div>
        </div>
        <hr style={{ color: '#C1C0C0' }}></hr>
        <div style={{ marginLeft: '1.26rem', marginTop: '1.75rem' }}>
          <div style={{ width: '30.25rem', height: '1.375rem' }}>
            <img
              src={require('../../assets/dashboard-assets/todo-star_outline.png')}
              style={{
                background: '#8CD6FF',
                marginLeft: '20px',
                marginRight: '10px',
                width: '1.875rem',
                height: '1.875rem',
              }}
            />
            <span>Awaiting Profiler Rating for the following candidate profiles (18)</span>
          </div>
        </div>
      </Paper>
    </>
  );
};
export default ToDo;
