import {
  Paper,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Button,
} from '@mui/material';
import { useState } from 'react';
import PageHeader from '../../../components/common/page-header/page-header';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DatePicker } from '@mui/x-date-pickers';
import NewLocationAutoComplete from '../common/new-location-autocomplete';

const NewSourceJobDefinitionCreate = () => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const handleLocationSelect = (locations: string[] = []) => {
    setSelectedLocations(locations);
    console.log('Selected locations:', locations);
  };
 
 const StepperList = () => {
  const [expanded, setExpanded] = useState<number | null>(1);
  const [activeStep, setActiveStep] = useState<number | null>(null);

  const handleExpand = (stepNumber: number) => {
    setExpanded(stepNumber === expanded ? null : stepNumber);
    setActiveStep(stepNumber);
  };

  const steps = [
    {
      number: 1,
      title: 'Job Definition Details For',
      description: 'Product Manager',
      location: 'Hyderabad, Amazon',
    },
    { number: 2, title: 'Sourcing Strategy' },
    { number: 3, title: 'Hiring Team' },
    { number: 4, title: 'Interview Process' },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.8rem',
        width: '18.5625rem',
        height: '19.5625rem',
        borderRight: '0.0625rem solid #C1C0C0',
        overflowY: 'auto', // Add scrolling if needed
      }}>
      {steps.map((step) => (
        <div
          key={step.number}
          onClick={() => handleExpand(step.number)}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            backgroundColor: expanded === step.number ? 'rgba(140, 214, 255, 0.50)' : '#fff',
            cursor: 'pointer',
            borderBottom: '0.0625rem solid #C1C0C0',
            borderLeft: expanded === step.number ? '0.0625rem solid #DC3857' : 'none',
            height: '4.3125rem', // Ensure consistent height for each step
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            flex: 1, // Ensure the step spreads evenly
          }}>
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: activeStep === step.number ? 'var(--Status-Red-500, #DC3857)' : '#D0D0D0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '1.25rem',
                color: activeStep === step.number ? '#DC3857' : '#F1F4F8',
              }}>
              <Typography variant='body2' style={{ color: '#FFF', fontWeight: 'bold' }}>
                {step.number}
              </Typography>
            </div>
            <div style={{ flex: 1 }}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: '400',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                {step.title}
              </Typography>
              {expanded === step.number && step.description && (
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '600',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {step.description}
                </Typography>
              )}
              {expanded === step.number && step.location && (
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '400',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                    {step.location}
                </Typography>
              )}
            </div>
          </div>
          <IconButton size='small'>
            <ArrowForwardIosIcon
              fontSize='small'
              style={{
                transform: expanded === step.number ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

  

  return (
    <>
      <div className='main-div'>
        <div style={{ padding: '0px 30px 30px 30px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '1.69rem', marginBottom: '1.19rem' }}>
            <PageHeader title='Job Requisition'></PageHeader>
          </div>
          <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
            <div className='paper' style={{ height: '19.5625rem', width: '18.5625rem' }}>
              <Paper sx={{ width: '18.5625rem' }}>
                <StepperList />
              </Paper>
            </div>
            <div
              style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                      Job Definition Details
                    </span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Outline the key responsibilities, required qualifications, and performance
                      expectations for a specific role.
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginRight: '3.05rem',
                      alignItems: 'center',
                      gap: '2.31rem',
                    }}>
                    <button
                      style={{
                        width: '11.375rem',
                        height: '3.125rem',
                        flexShrink: 0,
                        borderRadius: '0.3125rem',
                        background: 'var(--Primary-Blue-500, #1976D2)',
                        color: '#FFF',
                        border: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontWeight: 500,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0 1rem',
                        gap: '0.5rem',
                      }}>
                      <img
                        src={require('../../assets/dashboard-assets/Vector (4).png')}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                      />
                      Add Candidates
                    </button>
                  </div>
                </Paper>
              </div>
              <div style={{ height: '126rem', width: '100%' }}>
                <Paper className='paper' sx={{ width: '100%', height: '100%' }}>
                  <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Job Title *</Typography>
                        <span className='feildHeader'>
                          A concise label that summarizes the primary role
                        </span>
                      </div>
                      <div className='inputFeilds'>
                        <TextField
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                          }}></TextField>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities *</Typography>
                        <span className='feildHeader'>
                          Role Synopsis, Key Responsibilities & Qualifications
                        </span>
                      </div>
                      {/* <div style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                      <ReactQuill
                        className='reactQuill'
                        theme='snow'
                        value={value}
                        onChange={setValue}
                        style={{ height: '200px', border: 'none' }}
                      />
                    </div> */}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Job Location *</Typography>
                        <span className='feildHeader'>Pick one or multiple options</span>
                      </div>
                      <div>
                        {' '}
                        <div className='inputFeilds' style={{ marginBottom: '0.62rem' }}>
                          <NewLocationAutoComplete
                            onSelect={handleLocationSelect}
                            initialValue={selectedLocations}
                            width='35.4375rem !important'
                          />
                        </div>
                        <Chip
                          label='Deletable'
                          style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                          onDelete={() => console.log('dleeted')}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Domain *</Typography>
                      </div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Priority *</Typography>
                      </div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Operations *</Typography>
                        <span className='feildHeader'>Choose India or US</span>
                      </div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                    <div
                      style={{
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Hiring Manager *</Typography>
                      </div>
                      <div className='inputFeilds'>
                        <Autocomplete
                          id='combo-box-demo'
                          options={['keshava', 'karan']}
                          // value={formik.values.hiringManagers}
                          size='small'
                          sx={{ width: '35.4375rem !important' }}
                          limitTags={1}
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                          // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                          renderInput={(params) => <TextField {...params} label='' />}
                          onChange={(_e, val) => {
                            console.log(val);
                            // formik.setFieldValue('hiringManagers', val ? val : undefined);
                          }}
                          multiple
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Employment Type *</Typography>
                        <span className='feildHeader'>Pick one or multiple options</span>
                      </div>
                      <div>
                        <Autocomplete
                          className='inputFeilds'
                          id='combo-box-demo'
                          options={['keshava', 'karan']}
                          // value={formik.values.hiringManagers}
                          size='small'
                          sx={{ width: '35.4375rem !important', marginBottom: '0.62rem' }}
                          limitTags={1}
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                          // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                          renderInput={(params) => <TextField {...params} label='' />}
                          onChange={(_e, val) => {
                            console.log(val);
                            // formik.setFieldValue('hiringManagers', val ? val : undefined);
                          }}
                          multiple
                        />
                        <Chip
                          label='Deletable'
                          style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                          onDelete={() => console.log('dleeted')}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Working Model *</Typography>
                        <span className='feildHeader'>Choose Remote or Hybrid or Onsite</span>
                      </div>
                      <div>
                        <Select
                          className='inputFeilds'
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                            marginRight: '10px',
                          }}
                          size='small'
                          labelId='jobDefinitions'
                          name='jobDefinitions'
                          id='jobDefinitions-select'>
                          <MenuItem key={0}>All Job Definitions</MenuItem>
                          <MenuItem>Select</MenuItem>
                          <MenuItem>Select</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Salary (Full-time)</Typography>
                        <span className='feildHeader'>
                          Choose how you prefer to pay for this job
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '35.4375rem',
                          alignItems: 'center',
                        }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                          <Select
                            className='inputFeilds'
                            size='small'
                            sx={{
                              width: '15%',
                              textAlign: 'center',
                              borderRadius: '0.31rem 0rem 0rem 0.31rem',
                            }}
                            name='countryCode'
                            // value={formik.values.countryCode}
                            // defaultValue={DEFAULT_COUNTRY_CODE}
                            // onChange={(e) => {
                            //   formik.setFieldValue('countryCode', e.target.value);
                            // }}
                            // onBlur={formik.handleBlur}
                            // renderValue={(selected) => selected}
                          >
                            {/* {CountryCodes.map((option, index) => (
                            <MenuItem key={index} value={option.code} sx={{ fontSize: 'small' }}>
                              {option.name} ({option.code})
                            </MenuItem>
                          ))} */}
                          </Select>
                          <TextField
                            className='salaryType'
                            style={{ borderRadius: '0px 0px 0px 0px' }}
                            sx={{ width: '68%' }}
                            // value={formik.values.phoneNumber}
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                          />
                          <TextField
                            className='salaryContact'
                            sx={{ width: '17%', color: 'black' }}
                            value={'Annually'}
                            disabled
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Salary (Contract)</Typography>
                        <span className='feildHeader'>
                          Choose how you prefer to pay for this job
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '35.4375rem',
                          alignItems: 'center',
                        }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                          <Select
                            className='inputFeilds'
                            size='small'
                            sx={{
                              width: '15%',
                              textAlign: 'center',
                              borderRadius: '0.31rem 0rem 0rem 0.31rem',
                            }}
                            name='countryCode'
                            // value={formik.values.countryCode}
                            // defaultValue={DEFAULT_COUNTRY_CODE}
                            // onChange={(e) => {
                            //   formik.setFieldValue('countryCode', e.target.value);
                            // }}
                            // onBlur={formik.handleBlur}
                            // renderValue={(selected) => selected}
                          >
                            {/* {CountryCodes.map((option, index) => (
                            <MenuItem key={index} value={option.code} sx={{ fontSize: 'small' }}>
                              {option.name} ({option.code})
                            </MenuItem>
                          ))} */}
                          </Select>
                          <TextField
                            className='salaryType'
                            style={{ borderRadius: '0px 0px 0px 0px' }}
                            sx={{ width: '68%' }}
                            // value={formik.values.phoneNumber}
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                          />
                          <TextField
                            className='salaryContact'
                            sx={{ width: '17%', color: 'black' }}
                            value={'Hourly'}
                            disabled
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Skills *</Typography>
                        <span className='feildHeader'>Add skills suggested by AI</span>
                      </div>
                      <div>
                        <div className='inputFeilds' style={{ marginBottom: '0.62rem' }}>
                          <Autocomplete
                            id='combo-box-demo'
                            options={['keshava', 'karan']}
                            // value={formik.values.hiringManagers}
                            size='small'
                            sx={{ width: '35.4375rem !important' }}
                            limitTags={1}
                            getOptionLabel={(option: any) => option.name}
                            filterSelectedOptions
                            // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                            // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                            renderInput={(params) => <TextField {...params} label='' />}
                            onChange={(_e, val) => {
                              console.log(val);
                              // formik.setFieldValue('hiringManagers', val ? val : undefined);
                            }}
                            multiple
                          />
                        </div>
                        <div>
                          <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                          <Chip
                            label='Deletable'
                            style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                            onDelete={() => console.log('dleeted')}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Open Positions *</Typography>
                      </div>
                      <div className='inputFeilds'>
                        <TextField
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                          }}></TextField>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: '6.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Client *</Typography>
                      </div>
                      <div>
                        <Select
                          className='inputFeilds'
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                            marginRight: '10px',
                          }}
                          size='small'
                          labelId='jobDefinitions'
                          name='jobDefinitions'
                          id='jobDefinitions-select'>
                          <MenuItem key={0}>All Job Definitions</MenuItem>
                          <MenuItem>Select</MenuItem>
                          <MenuItem>Select</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Target Date *</Typography>
                      </div>
                      <div
                        className='inputFeilds'
                        style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                        <DatePicker
                          className='datepickers'
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                          }}></DatePicker>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Sponsorship Required?</Typography>
                      </div>
                      <div style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                        <RadioGroup
                          row
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          // value={value}
                          // onChange={handleChange}
                        >
                          <FormControlLabel value='female' control={<Radio />} label='No' />
                          <FormControlLabel value='male' control={<Radio />} label='Yes' />
                        </RadioGroup>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '3.31rem',
                      }}>
                      <div style={{ width: '319px' }}>
                        <Typography sx={{ fontWeight: 600 }}>Visa Type</Typography>
                        <span className='feildHeader'>
                          Check for Right to work with specific visa type
                        </span>
                      </div>
                      <div>
                        <Select
                          className='visaType'
                          sx={{
                            width: '35.4375rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                            marginRight: '10px',
                            background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                          }}
                          size='small'
                          name='jobDefinitions'
                          id='jobDefinitions-select'>
                          <MenuItem key={0}>All Job Definitions</MenuItem>
                          <MenuItem>Select</MenuItem>
                          <MenuItem>Select</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: '24.56rem',
                        alignItems: 'center',
                        marginBottom: '1.87rem',
                      }}>
                      <hr style={{ background: '#868686' }} />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '6.56rem',
                        alignItems: 'center',
                        marginBottom: '3.31rem',
                        justifyContent: 'space-between',
                      }}>
                      {/* <div style={{ gap: '20px', display: 'flex' }}> */}
                      <Button
                        className='button'
                        sx={{
                          width: '7.375rem',
                          height: '2.5rem',
                          borderRadius: '5px',
                          border: '1px solid #2D7DFE',
                          backgroundColor: '#FFF',
                          color: 'black',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: 400,
                        }}>
                        Cancel
                      </Button>
                      <Button
                        className='button'
                        sx={{
                          textTransform: 'none',
                          fontWeight: 400,
                          fontSize: '1rem',
                          width: '7.375rem',
                          height: '2.5rem',
                          marginRight: '10px',
                          color: 'white !important',
                          background: '#2D7DFE !important',
                        }}>
                        Next
                      </Button>
                      {/* </div> */}
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSourceJobDefinitionCreate;
