import { MenuItem, Select } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { DatePicker } from '@mui/x-date-pickers';

const ProfilerRatingAndAudit = () => {
  const data = [
    {
      name: 'JAN',
      'Candidates to Audit': 40,
      'Awaiting Profiler Rating': 24,
    },
    {
      name: 'FEB',
      'Candidates to Audit': 30,
      'Awaiting Profiler Rating': 13,
    },
    {
      name: 'MAR',
      'Candidates to Audit': 20,
      'Awaiting Profiler Rating': 0,
    },
    {
      name: 'APR',
      'Candidates to Audit': 27,
      'Awaiting Profiler Rating': 39,
    },
    {
      name: 'MAY',
      'Candidates to Audit': 18,
      'Awaiting Profiler Rating': 48,
    },
    {
      name: 'JUN',
      'Candidates to Audit': 23,
      'Awaiting Profiler Rating': 38,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
    {
      name: 'JUL',
      'Candidates to Audit': 34,
      'Awaiting Profiler Rating': 43,
    },
  ];

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            src={require('../../assets/dashboard-assets/Group (2).png')}
            style={{ marginLeft: '20px', marginRight: '10px' }}
          />
          <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
            Profiler Rating & Audit
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../assets/dashboard-assets/more_vert.png')}
            style={{ marginRight: '10px' }}
          />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0' }}></hr>
      <div style={{ display: 'flex', marginTop: '20px', marginBottom: '30px' }}>
        <div>
          <Select
            sx={{ width: '182px', height: '30px', marginRight: '10px', marginLeft: '26.61px' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'>
            <MenuItem key={0}>All Job Defintions</MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
        </div>
        <div>
          <Select
            sx={{ width: '135px', height: '30px', marginRight: '12px' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'>
            <MenuItem key={0}>All Recruiters</MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
          <span>Start Date</span>
          <DatePicker className='date' />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>End Date</span>
          <DatePicker className='date' />
        </div>
      </div>
      <ResponsiveContainer width='100%' height='64%'>
        <BarChart
          className='barchart'
          data={data}
          // barCategoryGap='0%'
          barGap={0}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid width={500} />
          <XAxis dataKey='name' width={500}></XAxis>
          <YAxis
            orientation='right'
            domain={[0, 100]}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey='Awaiting Profiler Rating' fill='#4D4690' minPointSize={10} />
          <Bar dataKey='Candidates to Audit' fill='#8CD6FF' minPointSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
export default ProfilerRatingAndAudit;
