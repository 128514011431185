import {
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Checkbox,
} from '@mui/material';
import PageHeader from '../../../components/common/page-header/page-header';
import { useState } from 'react';
import useNavigate from '../../../common/useNavigate';

interface JobDefinition {
  id: string;
  jobTitle: string;
  priority: string;
  createdOn: string;
  hiringManager: string;
  candidates: number;
  recruiters: string[];
  skillsRequired: string[];
  location: string;
  positions: number;
  status: string;
}

const jobDefinitions: JobDefinition[] = [
  {
    id: 'TAD1490',
    jobTitle: 'Product Manager',
    priority: 'HIGH',
    createdOn: '12 July, 2024',
    hiringManager: 'Srinivas Rao',
    candidates: 0,
    recruiters: ['recruiter1.png', 'recruiter2.png'],
    skillsRequired: ['Java', 'SQL', 'Spring 2.0'],
    location: 'Hyderabad',
    positions: 5,
    status: 'IN PROGRESS',
  },
  {
    id: 'TAD1234',
    jobTitle: 'Salesforce Developer',
    priority: 'MEDIUM',
    createdOn: '12 July, 2024',
    hiringManager: 'Kishore Kumar',
    candidates: 4,
    recruiters: ['recruiter1.png', 'recruiter2.png'],
    skillsRequired: ['Salesforce', 'Apex'],
    location: 'Hyderabad',
    positions: 1,
    status: 'COMPLETED',
  },
];

// const [statusOptions] = useState();
const statusOptions: any[] = ['In Progress', 'On Hold', 'Compleeted', 'Archived', 'Cancelled'];

// const [jobStatus, setJobStatus] = useState<{ [key: string]: string }>({
//   TAD1490: 'IN PROGRESS',
//   TAD1234: 'COMPLETED',
// });

const NewSourceJobDefinition = () => {
  const [selectedTab, setSelectedTab] = useState('All');
  const [navigate] = useNavigate();
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedJobs(jobDefinitions.map((job) => job.id));
    } else {
      setSelectedJobs([]);
    }
  };

  const handleSelectJob = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setSelectedJobs((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedJobs((prevSelected) => prevSelected.filter((selectedId) => selectedId !== id));
    }
  };

  // const handleStatusChange = (event: any, id: string) => {
  //   const newStatus = event.target.value;
  //   setJobStatus((prevStatus: any) => ({ ...prevStatus, [id]: newStatus }));
  // };

  const tabsHeaders = ['All', 'In Progress', 'On Hold', 'Completed', 'Published', 'Archieved'];

  return (
    <div className='main-div'>
      <div style={{ padding: '0px 30px 30px 30px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: '1.69rem', marginBottom: '1.19rem' }}>
          <PageHeader title='Job Definition'></PageHeader>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: '1.25rem', marginRight: '1.56rem', fontWeight: 600 }}>
              Job Definitions (11)
            </span>

            <IconButton
              style={{
                marginLeft: '12px',
                width: 'var(--Font-size-h2, 2.5rem)',
                height: 'var(--Font-size-h2, 2.5rem)',
              }}
              onClick={() =>
                navigate([
                  {
                    label: 'New Job Requisition',
                    link: '/source-job-definition/create-requisition',
                  },
                ])
              }>
              <img src={require('../../assets/sjd/Group 60.png')} />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              sx={{
                width: '9.375rem',
                height: 'var(--Font-size-h2, 2.5rem)',
                marginRight: '10px',
                marginLeft: '26.61px',
              }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'>
              <MenuItem key={0}>All Columns</MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
            <Select
              sx={{
                width: '7.9375rem',
                height: 'var(--Font-size-h2, 2.5rem)',
                marginRight: '0.81rem',
                marginLeft: '26.61px',
              }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'>
              <MenuItem key={0}>All Columns</MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
            <img
              src={require('../../assets/dashboard-assets/more_vert.png')}
              style={{ marginRight: '10px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '-7px', marginLeft: '-18px' }}>
          <Tabs className='tab' value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
            {tabsHeaders.map((header, index) => (
              <Tab
                sx={{
                  color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                  fontWeight: 400,
                  fontSize: '1rem',
                }}
                key={index}
                value={header}
                label={header}
              />
            ))}
          </Tabs>
        </div>
        <hr style={{ color: '#C1C0C0', margin: 0, marginBottom: '1.63rem' }}></hr>
        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={
                        jobDefinitions.length > 0 && selectedJobs.length === jobDefinitions.length
                      }
                      onChange={handleSelectAll}
                      inputProps={{ 'aria-label': 'select all jobs' }}
                    />
                  </TableCell>
                  <TableCell>Job Definition</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell>Hiring Manager</TableCell>
                  <TableCell>Candidates</TableCell>
                  <TableCell>Recruiters</TableCell>
                  <TableCell>Skills Required</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>No. of Positions</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobDefinitions.map((job) => (
                  <TableRow key={job.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedJobs.includes(job.id)}
                        onChange={(event) => handleSelectJob(event, job.id)}
                        inputProps={{ 'aria-label': `select job ${job.id}` }}
                      />
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          color: '#868686',
                          fontSize: '0.875rem',
                          display: 'flex',
                          fontWeight: '400',
                        }}>
                        {job.id}
                      </span>
                      <span
                        style={{
                          textDecoration: 'underline',
                          fontSize: '1rem',
                          fontWeight: 600,
                          color: '#1976D2',
                        }}>
                        {job.jobTitle}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={job.priority}
                        style={{
                          borderRadius: '0.3125rem',
                          backgroundColor: 'var(--Status-Red-200, #EFA3B2)',
                          color: '#333333',
                          fontWeight: '400',
                          textTransform: 'uppercase',
                        }}
                      />
                    </TableCell>
                    <TableCell>{job.createdOn}</TableCell>
                    <TableCell>{job.hiringManager}</TableCell>
                    <TableCell>
                      {job.candidates > 0 ? (
                        job.candidates
                      ) : (
                        <IconButton>
                          <img src={require('../../assets/sjd/Vector.png')} />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <Avatar
                        alt='Remy Sharp'
                        sx={{ width: '3.125rem', height: '3.09619rem' }}
                        src={require('../../assets/dashboard-assets/profileImage.png')}
                      />
                    </TableCell>
                    <TableCell>{job.skillsRequired.join(', ')}</TableCell>
                    <TableCell>{job.location}</TableCell>
                    <TableCell>{job.positions}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Chip
                          // label={jobStatus[job.id]}
                          label={'124gd'}
                          color={
                            // jobStatus[job.id] === 'IN PROGRESS'
                            //   ? 'primary'
                            //   : jobStatus[job.id] === 'COMPLETED'
                            //   ? 'success'
                            //   : 'default'
                            'primary'
                          }
                          style={{ marginRight: '8px', paddingRight: '0px' }}
                        />
                        <Select
                          // value={jobStatus[job.id]}
                          // onChange={(e) => handleStatusChange(e, job.id)}
                          sx={{ height: '1.5rem', minWidth: '120px', backgroundColor: 'green' }} // Adjust width and height as needed
                        >
                          {statusOptions.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
export default NewSourceJobDefinition;
