import { useState } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Select, MenuItem, Typography } from '@mui/material';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

const RecruiterPerformance = () => {
  const recruiterData: any = {
    'Mohana Malla': [
      {
        name: 'Submissions',
        target: 80,
        achieved: 80,
        achievedPercent: 100,
      },
      {
        name: 'QC Rated',
        target: 63,
        achieved: 63,
        achievedPercent: 100,
      },
      {
        name: 'PR Rated',
        target: 46,
        achieved: 34,
        achievedPercent: 72,
      },
      {
        name: 'Interviews',
        target: 28,
        achieved: 28,
        achievedPercent: 100,
      },
      {
        name: 'Offer Released',
        target: 21,
        achieved: 6,
        achievedPercent: 27,
      },
      {
        name: 'Onboarded',
        target: 7,
        achieved: 7,
        achievedPercent: 100,
      },
    ],
    'Kantabattina Divya': [
      {
        name: 'Submissions',
        target: 60,
        achieved: 55,
        achievedPercent: 92,
      },
      {
        name: 'QC Rated',
        target: 50,
        achieved: 45,
        achievedPercent: 90,
      },
      {
        name: 'PR Rated',
        target: 30,
        achieved: 28,
        achievedPercent: 93,
      },
      {
        name: 'Interviews',
        target: 20,
        achieved: 19,
        achievedPercent: 95,
      },
      {
        name: 'Offer Released',
        target: 15,
        achieved: 14,
        achievedPercent: 93,
      },
      {
        name: 'Onboarded',
        target: 10,
        achieved: 9,
        achievedPercent: 90,
      },
    ],
    'Kusuma Kumari': [
      {
        name: 'Submissions',
        target: 70,
        achieved: 65,
        achievedPercent: 93,
      },
      {
        name: 'QC Rated',
        target: 55,
        achieved: 50,
        achievedPercent: 91,
      },
      {
        name: 'PR Rated',
        target: 40,
        achieved: 35,
        achievedPercent: 88,
      },
      {
        name: 'Interviews',
        target: 25,
        achieved: 23,
        achievedPercent: 92,
      },
      {
        name: 'Offer Released',
        target: 18,
        achieved: 16,
        achievedPercent: 89,
      },
      {
        name: 'Onboarded',
        target: 8,
        achieved: 7,
        achievedPercent: 88,
      },
    ],
    'Gunasree Porandla': [
      {
        name: 'Submissions',
        target: 65,
        achieved: 63,
        achievedPercent: 97,
      },
      {
        name: 'QC Rated',
        target: 58,
        achieved: 55,
        achievedPercent: 95,
      },
      {
        name: 'PR Rated',
        target: 48,
        achieved: 45,
        achievedPercent: 94,
      },
      {
        name: 'Interviews',
        target: 30,
        achieved: 29,
        achievedPercent: 97,
      },
      {
        name: 'Offer Released',
        target: 20,
        achieved: 19,
        achievedPercent: 95,
      },
      {
        name: 'Onboarded',
        target: 10,
        achieved: 9,
        achievedPercent: 90,
      },
    ],
    'Geetha Reddy': [
      {
        name: 'Submissions',
        target: 75,
        achieved: 70,
        achievedPercent: 93,
      },
      {
        name: 'QC Rated',
        target: 60,
        achieved: 58,
        achievedPercent: 97,
      },
      {
        name: 'PR Rated',
        target: 45,
        achieved: 40,
        achievedPercent: 89,
      },
      {
        name: 'Interviews',
        target: 35,
        achieved: 32,
        achievedPercent: 91,
      },
      {
        name: 'Offer Released',
        target: 25,
        achieved: 23,
        achievedPercent: 92,
      },
      {
        name: 'Onboarded',
        target: 12,
        achieved: 11,
        achievedPercent: 92,
      },
    ],
    'Ramakrishna Pothuraju': [
      {
        name: 'Submissions',
        target: 80,
        achieved: 75,
        achievedPercent: 94,
      },
      {
        name: 'QC Rated',
        target: 70,
        achieved: 65,
        achievedPercent: 93,
      },
      {
        name: 'PR Rated',
        target: 55,
        achieved: 50,
        achievedPercent: 91,
      },
      {
        name: 'Interviews',
        target: 40,
        achieved: 38,
        achievedPercent: 95,
      },
      {
        name: 'Offer Released',
        target: 30,
        achieved: 28,
        achievedPercent: 93,
      },
      {
        name: 'Onboarded',
        target: 15,
        achieved: 14,
        achievedPercent: 93,
      },
    ],
    'Tejaswini Mood': [
      {
        name: 'Submissions',
        target: 60,
        achieved: 58,
        achievedPercent: 97,
      },
      {
        name: 'QC Rated',
        target: 50,
        achieved: 48,
        achievedPercent: 96,
      },
      {
        name: 'PR Rated',
        target: 45,
        achieved: 40,
        achievedPercent: 89,
      },
      {
        name: 'Interviews',
        target: 30,
        achieved: 28,
        achievedPercent: 93,
      },
      {
        name: 'Offer Released',
        target: 20,
        achieved: 19,
        achievedPercent: 95,
      },
      {
        name: 'Onboarded',
        target: 10,
        achieved: 9,
        achievedPercent: 90,
      },
    ],
    'Annie Sheeba': [
      {
        name: 'Submissions',
        target: 55,
        achieved: 50,
        achievedPercent: 91,
      },
      {
        name: 'QC Rated',
        target: 45,
        achieved: 42,
        achievedPercent: 93,
      },
      {
        name: 'PR Rated',
        target: 38,
        achieved: 35,
        achievedPercent: 92,
      },
      {
        name: 'Interviews',
        target: 28,
        achieved: 27,
        achievedPercent: 96,
      },
      {
        name: 'Offer Released',
        target: 20,
        achieved: 18,
        achievedPercent: 90,
      },
      {
        name: 'Onboarded',
        target: 8,
        achieved: 7,
        achievedPercent: 88,
      },
    ],
    'Bharath Paladi': [
      {
        name: 'Submissions',
        target: 75,
        achieved: 70,
        achievedPercent: 93,
      },
      {
        name: 'QC Rated',
        target: 65,
        achieved: 60,
        achievedPercent: 92,
      },
      {
        name: 'PR Rated',
        target: 55,
        achieved: 50,
        achievedPercent: 91,
      },
      {
        name: 'Interviews',
        target: 35,
        achieved: 33,
        achievedPercent: 94,
      },
      {
        name: 'Offer Released',
        target: 25,
        achieved: 23,
        achievedPercent: 92,
      },
      {
        name: 'Onboarded',
        target: 12,
        achieved: 11,
        achievedPercent: 92,
      },
    ],
    'Akshaya Kukatla': [
      {
        name: 'Submissions',
        target: 65,
        achieved: 60,
        achievedPercent: 92,
      },
      {
        name: 'QC Rated',
        target: 55,
        achieved: 50,
        achievedPercent: 91,
      },
      {
        name: 'PR Rated',
        target: 45,
        achieved: 40,
        achievedPercent: 89,
      },
      {
        name: 'Interviews',
        target: 30,
        achieved: 28,
        achievedPercent: 93,
      },
      {
        name: 'Offer Released',
        target: 20,
        achieved: 19,
        achievedPercent: 95,
      },
      {
        name: 'Onboarded',
        target: 10,
        achieved: 9,
        achievedPercent: 90,
      },
    ],
    'Priyanka Erla': [
      {
        name: 'Submissions',
        target: 70,
        achieved: 65,
        achievedPercent: 93,
      },
      {
        name: 'QC Rated',
        target: 60,
        achieved: 55,
        achievedPercent: 92,
      },
      {
        name: 'PR Rated',
        target: 50,
        achieved: 45,
        achievedPercent: 90,
      },
      {
        name: 'Interviews',
        target: 35,
        achieved: 33,
        achievedPercent: 94,
      },
      {
        name: 'Offer Released',
        target: 25,
        achieved: 23,
        achievedPercent: 92,
      },
      {
        name: 'Onboarded',
        target: 15,
        achieved: 14,
        achievedPercent: 93,
      },
    ],
  };

  const recruiters = Object.keys(recruiterData);

  const [selectedRecruiter, setSelectedRecruiter] = useState<string>('Mohana Malla');
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  // const handleStartDateChange = (date: Date) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date: Date) => {
  //   setEndDate(date);
  // };

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            src={require('../../assets/dashboard-assets/Group (1).png')}
            style={{ marginLeft: '20px', marginRight: '10px' }}
            alt='Jobs Closed Icon'
          />
          <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
            Recruiter Performance
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../assets/dashboard-assets/more_vert.png')}
            style={{ marginRight: '10px' }}
            alt='Options'
          />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0', margin: 0 }}></hr>
      <Box display='flex'>
        <Box
          sx={{
            width: '25.9375rem',
            height: '50.3125rem',
            borderRight: '0.0625rem solid #C1C0C0',
          }}>
          {recruiters.map((recruiter) => (
            <div
              style={{
                borderLeft: '0.625rem solid red',
                width: '25.3125rem',
                height: '4.3125rem',
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '0.0625rem solid #C1C0C0',
                background: recruiter === selectedRecruiter ? 'rgba(140, 214, 255, 0.50)' : '',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedRecruiter(recruiter)}
              key={recruiter}>
              <span
                style={{
                  fontSize: '1rem',
                  fontWeight: recruiter === selectedRecruiter ? 600 : 400,
                  marginLeft: '3.44rem',
                }}>
                {recruiter}
              </span>
              {recruiter === selectedRecruiter && (
                <img
                  src={require('../../assets/dashboard-assets/arrow_forward_ios.png')}
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                />
              )}
            </div>
          ))}
        </Box>

        <Box width='100%' maxWidth='90%' margin='0 auto' padding='10px'>
          <div
            style={{
              flex: 1,
              padding: '10px',
              marginTop: '30px',
              marginBottom: '25px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: '1350px',
              marginLeft: '10px',
              marginRight: 'auto',
            }}>
            <Box display='flex' gap='1rem'>
              <Select
                sx={{ width: '182px', height: '30px', marginRight: '10px' }}
                size='small'
                labelId='jobDefinitions'
                name='jobDefinitions'
                id='jobDefinitions-select'>
                <MenuItem key={0}>All Job Definitions</MenuItem>
                <MenuItem>Select</MenuItem>
                <MenuItem>Select</MenuItem>
              </Select>
              <Select
                sx={{ width: '135px', height: '30px', marginRight: '12px' }}
                size='small'
                labelId='recruiters'
                name='recruiters'
                id='recruiters-select'>
                <MenuItem key={0}>All Recruiters</MenuItem>
                <MenuItem>Select</MenuItem>
                <MenuItem>Select</MenuItem>
              </Select>
              <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span style={{ marginRight: '10px' }}>Start Date</span>
                <DatePicker className='date' />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span style={{ marginRight: '10px' }}>End Date</span>
                <DatePicker className='date' />
              </div>
            </Box>
          </div>
          <div
            style={{
              flex: 1,
              padding: '10px',
              marginTop: '30px',
              marginBottom: '25px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: '1350px',
              marginLeft: '10px',
              marginRight: 'auto',
            }}>
            <Box display='flex' gap='1rem'>
              <Select
                sx={{ width: '182px', height: '30px', marginRight: '10px' }}
                size='small'
                labelId='jobDefinitions'
                name='jobDefinitions'
                id='jobDefinitions-select'>
                <MenuItem key={0}>Overall Performance</MenuItem>
                <MenuItem>Select</MenuItem>
                <MenuItem>Select</MenuItem>
              </Select>
            </Box>
          </div>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='400px'
            width='80%'
            position='relative'
            paddingRight='30px'
            marginTop='180px'
            marginLeft='120px'>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart
                width={500}
                height={400}
                data={recruiterData[selectedRecruiter]}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}>
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='name' scale='band' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey='achieved' barSize={30} fill='#90CAF9'>
                  <LabelList dataKey='achieved' position='top' />
                </Bar>
                <Bar dataKey='target' barSize={30} fill='#1565C0'>
                  <LabelList dataKey='target' position='top' />
                </Bar>
                <Line type='monotone' dataKey='achievedPercent' stroke='#4CAF50' strokeWidth={2}>
                  <LabelList
                    dataKey='achievedPercent'
                    position='top'
                    formatter={(value: any) => `${value}%`}
                  />
                </Line>
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
          <div style={{ marginTop: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}>
              <Typography variant='caption'>High</Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '8px',
                  margin: '0 10px',
                  background:
                    'linear-gradient(to right, #69D989, #D9D969, #FDC669, #FD9669, #FD6969)',
                }}
              />
              <Typography variant='caption'>Low</Typography>
              <Typography variant='body2'>Performance Indicator</Typography>
            </Box>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '9.8rem' }}>High</div>
            <div
              style={{
                width: '2.70706rem ',
                height: '0.98038rem',
                background: '#61BB46',
              }}></div>
          </div>
        </Box>
      </Box>
    </>
  );
};
export default RecruiterPerformance;
