// import { MenuItem, Select } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import OpenPositions from './open-positions';
import OnBoardingMatrix from './on-boarding-matrix';
import SourceQcPrCount from './source-qc-pr-count';
import AverageTadUpload from './average.tad-upload';
import CandidateBySource from './candidate-by-source';
import TotalHires from './total-hires';
import HiresByGender from './hires-by-gender';
import OfferAcceptanceAndRejection from './offer-acceptance-and-rejection';
import OfferAcceptanceRate from './offere-acceptance-rate';
import HiredByMonth from './hires-by-month';

const Analytics = () => {
  const items = [
    'Open Positions',
    'Onboarding Matrix',
    'Source, QC & PR Count',
    'Average TAD Upload',
    'Candidate by Source',
    'Total Hires',
    'Hires By Gender',
    'Offer Acceptance & Rejection',
    'Offer Acceptance Rate',
    'Hires By Month',
  ];

  const [selectedComponent, setSelectedComponent] = useState('Open Positions');

  return (
    <>
      <div
        style={{
          height: '3.81rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            src={require('../../../assets/dashboard-assets/trending_up.png')}
            style={{ marginLeft: '20px', marginRight: '10px' }}
          />
          <span className='heading' style={{ height: '24px', fontSize: '18px' }}>
            Analytics
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../../assets/dashboard-assets/more_vert.png')}
            style={{ marginRight: '10px' }}
          />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0', margin: 0 }}></hr>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: '25.9375rem',
            height: '53.3125rem',
            borderRight: '0.0625rem solid #C1C0C0',
          }}>
          {items.map((item) => (
            <div
              style={{
                width: '25.9375rem',
                height: '4.3125rem',
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '0.0625rem solid #C1C0C0',
                background: item === selectedComponent ? 'rgba(140, 214, 255, 0.50)' : '',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedComponent(item)}
              key={item}>
              <span
                style={{
                  fontSize: '1rem',
                  fontWeight: item === selectedComponent ? 600 : 400,
                  marginLeft: '3.44rem',
                }}>
                {item}
              </span>
              {item === selectedComponent && (
                <img
                  src={require('../../../assets/dashboard-assets/arrow_forward_ios.png')}
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                />
              )}
            </div>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              flex: 1,
              marginTop: '20px',
              marginBottom: '25px',
              height: '1.875rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {/* <div
              style={{
                display: 'flex',
              }}>
              <div>
                <Select
                  sx={{
                    width: '182px',
                    height: '30px',
                    marginRight: '10px',
                    marginLeft: '26.61px',
                  }}
                  size='small'
                  fullWidth
                  labelId='domain'
                  name='domain'
                  id='demo-simple-select'>
                  <MenuItem key={0}>All Job Defintions</MenuItem>
                  <MenuItem>Select</MenuItem>
                  <MenuItem>Select</MenuItem>
                </Select>
              </div>
              <div>
                <Select
                  sx={{ width: '135px', height: '30px', marginRight: '12px' }}
                  size='small'
                  fullWidth
                  labelId='domain'
                  name='domain'
                  id='demo-simple-select'>
                  <MenuItem key={0}>All Recruiters</MenuItem>
                  <MenuItem>Select</MenuItem>
                  <MenuItem>Select</MenuItem>
                </Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
                <span>Start Date</span>
                <DatePicker className='date' />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>End Date</span>
                <DatePicker className='date' />
              </div>
            </div>
            <div style={{ height: '1.875rem' }}>
              <span
                style={{
                  fontSize: '1rem',
                  color: '#1976D2',
                  fontWeight: '400',
                  marginRight: '20px',
                  textDecoration: 'underline',
                }}>
                View All
              </span>
            </div> */}
          </div>
          {selectedComponent === 'Open Positions' && <OpenPositions />}
          {selectedComponent === 'Onboarding Matrix' && <OnBoardingMatrix />}
          {selectedComponent === 'Source, QC & PR Count' && <SourceQcPrCount />}
          {selectedComponent === 'Average TAD Upload' && <AverageTadUpload />}
          {selectedComponent === 'Candidate by Source' && <CandidateBySource />}
          {selectedComponent === 'Total Hires' && <TotalHires />}
          {selectedComponent === 'Hires By Gender' && <HiresByGender />}
          {selectedComponent === 'Offer Acceptance & Rejection' && <OfferAcceptanceAndRejection />}
          {selectedComponent === 'Offer Acceptance Rate' && <OfferAcceptanceRate />}
          {selectedComponent === 'Hires By Month' && <HiredByMonth />}
        </div>
      </div>
    </>
  );
};
export default Analytics;
