import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PageHeader from '../../../components/common/page-header/page-header';
import { useState } from 'react';
import NewLocationAutoComplete from '../common/new-location-autocomplete';
import { DatePicker } from '@mui/x-date-pickers';
// import { ReactComponent as Visibility } from '../../assets/sjd/close.svg';

// import ReactQuill, { Quill } from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { useState } from 'react';

// Quill.register('modules/history', true);

const NewSourceJobRequisitionCreate = () => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const handleLocationSelect = (locations: string[] = []) => {
    setSelectedLocations(locations);
    console.log('Selected locations:', locations);
  };

  return (
    <div className='main-div'>
      <div style={{ padding: '0px 30px 30px 30px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: '1.69rem', marginBottom: '1.19rem' }}>
          <PageHeader title='Job Requisition'></PageHeader>
        </div>
        <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
          <div className='paper' style={{ height: '13.8125rem' }}>
            <Paper sx={{ height: '100%', width: '18.5625rem' }}>
              <div
                style={{
                  paddingTop: '1.25rem',
                  paddingLeft: '1.12rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <div
                  style={{
                    width: '1.7rem',
                    height: '1.5rem',
                    marginRight: '1.56rem',
                    borderRadius: '11px',
                    backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                  }}>
                  <img src={require('../../assets/sjd/check.png')} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <span style={{ fontSize: '1rem', fontWeight: 600 }}>New Job Requisition</span>

                  <span style={{ fontSize: '0.875rem', fontWeight: 400 }}>
                    {`Outline the specific need for a new hire, detailing the role,
                        responsibilities, justification and how it fits into the company's strategic
                        goals.`}
                  </span>
                </div>
                <img
                  src={require('../../assets/sjd/chevron_right.png')}
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                />
              </div>
            </Paper>
          </div>
          <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
            <div
              className='paper'
              style={{
                height: '8.8125rem',
                width: '100%',
              }}>
              <Paper
                className='new-job-requisition'
                sx={{
                  width: '100%',
                  height: '100%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '1.88rem',
                    gap: '7px',
                  }}>
                  <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>New Job Requisition</span>
                  <span
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: 400,
                      color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                    }}>
                    Creating a job allows you to add candidates and advertise it on your career page
                    and job boards.
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginRight: '2.5rem',
                    alignItems: 'center',
                    gap: '2.31rem',
                  }}>
                  <div
                    style={{
                      width: '17.75rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      borderRadius: '0.3125rem',
                      border: '1px solid #2D7DFE',
                      background: 'var(--Secondary-colors-White, #FFF)',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <img
                      src={require('../../assets/sjd/Group 343.png')}
                      style={{
                        marginLeft: '0.63rem',
                        marginRight: '10px',
                        color: 'var(--Primary-Dark-grey-500, #333)',
                      }}
                    />
                    <span style={{ fontWeight: 400, fontSize: '1rem' }}>
                      Ask AI to write a job requisition
                    </span>
                  </div>
                  <img
                    src={require('../../assets/dashboard-assets/more_vert.png')}
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                  />
                </div>
              </Paper>
            </div>
            <div style={{ height: '126rem', width: '100%' }}>
              <Paper className='paper' sx={{ width: '100%', height: '100%' }}>
                <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Job Title *</Typography>
                      <span className='feildHeader'>
                        A concise label that summarizes the primary role
                      </span>
                    </div>
                    <div className='inputFeilds'>
                      <TextField
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        }}></TextField>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities *</Typography>
                      <span className='feildHeader'>
                        Role Synopsis, Key Responsibilities & Qualifications
                      </span>
                    </div>
                    {/* <div style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                      <ReactQuill
                        className='reactQuill'
                        theme='snow'
                        value={value}
                        onChange={setValue}
                        style={{ height: '200px', border: 'none' }}
                      />
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Job Location *</Typography>
                      <span className='feildHeader'>Pick one or multiple options</span>
                    </div>
                    <div>
                      {' '}
                      <div className='inputFeilds' style={{ marginBottom: '0.62rem' }}>
                        <NewLocationAutoComplete
                          onSelect={handleLocationSelect}
                          initialValue={selectedLocations}
                          width='35.4375rem !important'
                        />
                      </div>
                      <Chip
                        label='Deletable'
                        style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                        onDelete={() => console.log('dleeted')}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Domain *</Typography>
                    </div>
                    <Select
                      className='inputFeilds'
                      sx={{
                        width: '35.4375rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      labelId='jobDefinitions'
                      name='jobDefinitions'
                      id='jobDefinitions-select'>
                      <MenuItem key={0}>All Job Definitions</MenuItem>
                      <MenuItem>Select</MenuItem>
                      <MenuItem>Select</MenuItem>
                    </Select>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Priority *</Typography>
                    </div>
                    <Select
                      className='inputFeilds'
                      sx={{
                        width: '35.4375rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      labelId='jobDefinitions'
                      name='jobDefinitions'
                      id='jobDefinitions-select'>
                      <MenuItem key={0}>All Job Definitions</MenuItem>
                      <MenuItem>Select</MenuItem>
                      <MenuItem>Select</MenuItem>
                    </Select>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Operations *</Typography>
                      <span className='feildHeader'>Choose India or US</span>
                    </div>
                    <Select
                      className='inputFeilds'
                      sx={{
                        width: '35.4375rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      labelId='jobDefinitions'
                      name='jobDefinitions'
                      id='jobDefinitions-select'>
                      <MenuItem key={0}>All Job Definitions</MenuItem>
                      <MenuItem>Select</MenuItem>
                      <MenuItem>Select</MenuItem>
                    </Select>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Hiring Manager *</Typography>
                    </div>
                    <div className='inputFeilds'>
                      <Autocomplete
                        id='combo-box-demo'
                        options={['keshava', 'karan']}
                        // value={formik.values.hiringManagers}
                        size='small'
                        sx={{ width: '35.4375rem !important' }}
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                        // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                        renderInput={(params) => <TextField {...params} label='' />}
                        onChange={(_e, val) => {
                          console.log(val);
                          // formik.setFieldValue('hiringManagers', val ? val : undefined);
                        }}
                        multiple
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Employment Type *</Typography>
                      <span className='feildHeader'>Pick one or multiple options</span>
                    </div>
                    <div>
                      <Autocomplete
                        className='inputFeilds'
                        id='combo-box-demo'
                        options={['keshava', 'karan']}
                        // value={formik.values.hiringManagers}
                        size='small'
                        sx={{ width: '35.4375rem !important', marginBottom: '0.62rem' }}
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                        // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                        renderInput={(params) => <TextField {...params} label='' />}
                        onChange={(_e, val) => {
                          console.log(val);
                          // formik.setFieldValue('hiringManagers', val ? val : undefined);
                        }}
                        multiple
                      />
                      <Chip
                        label='Deletable'
                        style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                        onDelete={() => console.log('dleeted')}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Working Model *</Typography>
                      <span className='feildHeader'>Choose Remote or Hybrid or Onsite</span>
                    </div>
                    <div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Preferred Qualification</Typography>
                      <span className='feildHeader'>
                        Choose required qualification of the candidate
                      </span>
                    </div>
                    <div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Experience Range</Typography>
                      <span className='feildHeader'>Choose how many years of experience range</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '35.4375rem',
                        gap: '14px',
                        alignItems: 'center',
                      }}>
                      <div className='inputFeilds'>
                        <TextField
                          type='number'
                          sx={{
                            width: '10.625rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                          }}></TextField>
                      </div>
                      <div>
                        <span style={{ fontWeight: 400, fontSize: '1rem' }}>to</span>
                      </div>
                      <div className='inputFeilds'>
                        <TextField
                          type='number'
                          sx={{
                            width: '10.625rem',
                            height: 'var(--Font-size-h2, 2.5rem)',
                          }}></TextField>
                      </div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '10.625rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Salary (Full-time)</Typography>
                      <span className='feildHeader'>Choose how you prefer to pay for this job</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '35.4375rem',
                        alignItems: 'center',
                      }}>
                      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <Select
                          className='inputFeilds'
                          size='small'
                          sx={{
                            width: '15%',
                            textAlign: 'center',
                            borderRadius: '0.31rem 0rem 0rem 0.31rem',
                          }}
                          name='countryCode'
                          // value={formik.values.countryCode}
                          // defaultValue={DEFAULT_COUNTRY_CODE}
                          // onChange={(e) => {
                          //   formik.setFieldValue('countryCode', e.target.value);
                          // }}
                          // onBlur={formik.handleBlur}
                          // renderValue={(selected) => selected}
                        >
                          {/* {CountryCodes.map((option, index) => (
                            <MenuItem key={index} value={option.code} sx={{ fontSize: 'small' }}>
                              {option.name} ({option.code})
                            </MenuItem>
                          ))} */}
                        </Select>
                        <TextField
                          className='salaryType'
                          style={{ borderRadius: '0px 0px 0px 0px' }}
                          sx={{ width: '68%' }}
                          // value={formik.values.phoneNumber}
                          fullWidth
                          id='phoneNumber'
                          size='small'
                          name='phoneNumber'
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        <TextField
                          className='salaryContact'
                          sx={{ width: '17%', color: 'black' }}
                          value={'Annually'}
                          disabled
                          fullWidth
                          id='phoneNumber'
                          size='small'
                          name='phoneNumber'
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Salary (Contract)</Typography>
                      <span className='feildHeader'>Choose how you prefer to pay for this job</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '35.4375rem',
                        alignItems: 'center',
                      }}>
                      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <Select
                          className='inputFeilds'
                          size='small'
                          sx={{
                            width: '15%',
                            textAlign: 'center',
                            borderRadius: '0.31rem 0rem 0rem 0.31rem',
                          }}
                          name='countryCode'
                          // value={formik.values.countryCode}
                          // defaultValue={DEFAULT_COUNTRY_CODE}
                          // onChange={(e) => {
                          //   formik.setFieldValue('countryCode', e.target.value);
                          // }}
                          // onBlur={formik.handleBlur}
                          // renderValue={(selected) => selected}
                        >
                          {/* {CountryCodes.map((option, index) => (
                            <MenuItem key={index} value={option.code} sx={{ fontSize: 'small' }}>
                              {option.name} ({option.code})
                            </MenuItem>
                          ))} */}
                        </Select>
                        <TextField
                          className='salaryType'
                          style={{ borderRadius: '0px 0px 0px 0px' }}
                          sx={{ width: '68%' }}
                          // value={formik.values.phoneNumber}
                          fullWidth
                          id='phoneNumber'
                          size='small'
                          name='phoneNumber'
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        <TextField
                          className='salaryContact'
                          sx={{ width: '17%', color: 'black' }}
                          value={'Hourly'}
                          disabled
                          fullWidth
                          id='phoneNumber'
                          size='small'
                          name='phoneNumber'
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Primary Skills *</Typography>
                      <span className='feildHeader'>Add skills suggested by AI</span>
                    </div>
                    <div>
                      <div className='inputFeilds' style={{ marginBottom: '0.62rem' }}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={['keshava', 'karan']}
                          // value={formik.values.hiringManagers}
                          size='small'
                          sx={{ width: '35.4375rem !important' }}
                          limitTags={1}
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                          // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                          renderInput={(params) => <TextField {...params} label='' />}
                          onChange={(_e, val) => {
                            console.log(val);
                            // formik.setFieldValue('hiringManagers', val ? val : undefined);
                          }}
                          multiple
                        />
                      </div>
                      <div>
                        <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                        <Chip
                          label='Deletable'
                          style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                          onDelete={() => console.log('dleeted')}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Secondary Skills *</Typography>
                      <span className='feildHeader'>Add skills suggested by AI</span>
                    </div>
                    <div>
                      <div className='inputFeilds' style={{ marginBottom: '0.62rem' }}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={['keshava', 'karan']}
                          // value={formik.values.hiringManagers}
                          size='small'
                          sx={{ width: '35.4375rem !important' }}
                          limitTags={1}
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          // onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                          // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                          renderInput={(params) => <TextField {...params} label='' />}
                          onChange={(_e, val) => {
                            console.log(val);
                            // formik.setFieldValue('hiringManagers', val ? val : undefined);
                          }}
                          multiple
                        />
                      </div>
                      <div>
                        <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                        <Chip
                          label='Deletable'
                          style={{ backgroundColor: '#D9D9D9', borderRadius: '0.63rem' }}
                          avatar={
                            <Avatar
                              alt='Natacha'
                              sx={{ width: '15px  ', height: '21px' }}
                              src={require('../../assets/sjd/add.png')}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Open Positions *</Typography>
                    </div>
                    <div className='inputFeilds'>
                      <TextField
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        }}></TextField>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '6.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Client *</Typography>
                    </div>
                    <div>
                      <Select
                        className='inputFeilds'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                        }}
                        size='small'
                        labelId='jobDefinitions'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Target Date *</Typography>
                    </div>
                    <div className='inputFeilds' style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                      <DatePicker
                        className='datepickers'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        }}></DatePicker>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Sponsorship Required?</Typography>
                    </div>
                    <div style={{ height: 'var(--Font-size-h2, 2.5rem)' }}>
                      <RadioGroup
                        row
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        // value={value}
                        // onChange={handleChange}
                      >
                        <FormControlLabel value='female' control={<Radio />} label='No' />
                        <FormControlLabel value='male' control={<Radio />} label='Yes' />
                      </RadioGroup>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '3.31rem',
                    }}>
                    <div style={{ width: '319px' }}>
                      <Typography sx={{ fontWeight: 600 }}>Visa Type</Typography>
                      <span className='feildHeader'>
                        Check for Right to work with specific visa type
                      </span>
                    </div>
                    <div>
                      <Select
                        className='visaType'
                        sx={{
                          width: '35.4375rem',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginRight: '10px',
                          background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                        }}
                        size='small'
                        name='jobDefinitions'
                        id='jobDefinitions-select'>
                        <MenuItem key={0}>All Job Definitions</MenuItem>
                        <MenuItem>Select</MenuItem>
                        <MenuItem>Select</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <hr style={{ background: '#868686' }} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '6.56rem',
                      alignItems: 'center',
                      marginBottom: '3.31rem',
                      justifyContent: 'space-between',
                    }}>
                    <div
                      style={{
                        width: '319px',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        borderRadius: '0.3125rem',
                        border: '1px solid #2D7DFE',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <img
                        src={require('../../assets/sjd/Group 343.png')}
                        style={{
                          marginLeft: '0.63rem',
                          marginRight: '10px',
                          color: 'var(--Primary-Dark-grey-500, #333)',
                        }}
                      />
                      <span style={{ fontWeight: 400, fontSize: '1rem' }}>
                        Ask AI to write a job requisition
                      </span>
                    </div>
                    <div style={{ gap: '20px', display: 'flex' }}>
                      <Button
                        className='button'
                        sx={{
                          width: '7.375rem',
                          height: '2.5rem',
                          borderRadius: '5px',
                          border: '1px solid #2D7DFE',
                          backgroundColor: '#FFF',
                          color: 'black',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: 400,
                        }}>
                        Cancel
                      </Button>
                      <Button
                        className='button'
                        sx={{
                          textTransform: 'none',
                          fontWeight: 400,
                          fontSize: '1rem',
                          width: '7.375rem',
                          height: '2.5rem',
                          marginRight: '10px',
                          color: 'white !important',
                          background: '#2D7DFE !important',
                        }}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewSourceJobRequisitionCreate;
