import axiosClient from '../common/axiosClient';

export async function postPermissions(
  request: any,
  role?: string,
  moduleId?: any,
  subModuleId?: any,
  isActive?: any,
  subModuleWrite?: any,
  subModuleDelete?: any,
) {
  return axiosClient.post('/permission', request, {
    params: { role, moduleId, subModuleId, isActive, subModuleWrite, subModuleDelete },
  });
}

export async function getPermissions(roleId?: any) {
  return axiosClient.get('/permission', { params: { roleId } });
}

export async function getPermissionsForUser() {
  return axiosClient.get('/permission/roles');
}
